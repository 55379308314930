import React from "react";
import { useSelector } from "react-redux";
import BackDashboard from "@/components/BackDashboard";
import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import PageShimmer from "@/components/PageShimmer";
import AdministratorTableList from "@/components/AdministratorTable";
import TableWrapper from "@/components/TableWrapper";
import { TXDTable } from "@supporttxend/txd-components";
import { IAdministratorsDashboardProps } from "@/interfaces";
import getPagePermissionList from "@/services/getPagePermission";
import { SiteId } from "@/constants/constant";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import { RootState } from "@/redux/store";
import { getPageId } from "@/utils/componentUtils";

const AdministratorsDashboard: React.FC<IAdministratorsDashboardProps> = ({
  data,
}) => {
  const userPolicy = useSelector((state: RootState) => state.auth.policy);
  const pageId = getPageId(userPolicy?.PolicyId);
  const pageData = getPagePermissionList(SiteId, pageId);
  const isLoading = !data?.userProfile || !data || !pageData;

  if (isLoading) {
    return <PageShimmer data={data} />;
  }

  const hasPlanAdminsList = findComponent("PLAN ADMINS LIST", pageData);
  const componentPermissions = hasPlanAdminsList
    ? findPermissionList("PLAN ADMINS LIST", pageData)
    : null;

  return (
    <PageWrapper>
      <NavHeader data={data} />
      <BackDashboard />
      <TableWrapper>
        {hasPlanAdminsList ? (
          <AdministratorTableList componentPermissions={componentPermissions} />
        ) : (
          <TXDTable
            filteration={true}
            pagination={true}
            entries={true}
            tHead={["id", "Name", "Assets", "Status", "Actions"]}
            tRow={[]}
            title="Plan Administrators"
          />
        )}
      </TableWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default AdministratorsDashboard;
