import React, { Fragment, useCallback, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import useUploadParticipant from "@/services/useUploadParticipant";
import { ERROR_MESSAGES } from "../skeletons/toastUtils";
import {
  TXDBox,
  TXDFlex,
  TXDImage,
  TXDText,
  TXDButton,
} from "@supporttxend/txd-components";
import upload_icon from "../../assets/images/uplaod_icon.png";
import { IUploadFileModalProps } from "@/interfaces";
import SaveSpinner from "../skeletons/SaveSpinner";

const acceptedFileTypes = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const UploadFileModal: React.FC<IUploadFileModalProps> = ({
  modalOpen,
  closeModal,
}) => {
  // console.log(closeModal, "this is close modal");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { uploadParticipant, loading } = useUploadParticipant(
    selectedFile,
    closeModal
  );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      toast(ERROR_MESSAGES.FILE_SELECTED_FAILED, {
        icon: "⚠️",
        style: { color: "#000" },
      });
    }

    const file = acceptedFiles[0];

    if (!acceptedFileTypes.includes(file.type)) {
      toast(ERROR_MESSAGES.UNSUPPORTED_FILE_FAILED, {
        icon: "⚠️",
        style: { color: "#000" },
      });

      return;
    }

    setSelectedFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
  });
  const handleUpload = async (event: React.FormEvent) => {
    event.preventDefault();
    if (uploadParticipant) {
      try {
        await uploadParticipant();
        setSelectedFile(null);
      } catch (error: any) {
        console.error(ERROR_MESSAGES.FILE_UPLOAD_FAILED || error.message);
      }
    }
  };

  return (
    <Transition appear show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center"
        onClose={() => {
          //Do nothing
        }}
        data-testid="uploadFileModal"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>
        <span className="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <TXDBox className="inline-block  w-full max-w-2xl my-8 mx-12  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
            <TXDFlex justifyContent="center" alignItems="center">
              <TXDBox className="modalOverlay w-full">
                <TXDBox className="fixed top-0 left-0 right-0 z-30 bg-white">
                  <TXDFlex
                    alignItems="center"
                    className="p-4 flex justify-between border-b"
                  >
                    <TXDText
                      align="left"
                      as="h1"
                      className="uppercase text-gray-500 sm:text-xl text-sm whitespace-nowrap "
                      size="xl"
                      weight="normal"
                    >
                      Upload SpreadSheet
                    </TXDText>
                    <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                        onClick={closeModal}
                      />
                    </TXDBox>
                  </TXDFlex>
                </TXDBox>
              </TXDBox>
            </TXDFlex>
            {/* <TXDFlex alignItems="center" className="p-4 flex justify-between ">
              <TXDText
                align="left"
                as="h1"
                className="uppercase text-gray-500 sm:text-xl text-sm whitespace-nowrap  "
                size="xl"
                weight="normal"
              >
                Please upload your document
              </TXDText>
              <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                <FontAwesomeIcon
                  icon={faTimes}
                  className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                  onClick={closeModal}
                />
              </TXDBox>
            </TXDFlex> */}
            <form action="" onSubmit={handleUpload}>
              <TXDFlex
                justifyContent="center"
                alignItems="center"
                className="mt-20"
              >
                <TXDBox className="w-full min-h-full">
                  <TXDFlex className="p-2 h-full flex-col gap-8">
                    <TXDFlex
                      className="w-full h-full flex-col"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TXDBox
                        className="w-full max-w-[600px] cursor-pointer"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <TXDFlex
                          className="w-full h-[300px] border-2 border-dashed border-spacing-2 border-[#62BCE5] p-6 gap-3 flex-col"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <TXDImage src={upload_icon} alt="upload_icon" />
                          <TXDFlex className="flex gap-1" alignItems="center">
                            {isDragActive ? (
                              <TXDText as="h1" weight="normal" size="base">
                                Drop the files here ...
                              </TXDText>
                            ) : (
                              <>
                                {selectedFile ? (
                                  <p>{selectedFile.name}</p>
                                ) : (
                                  <TXDText as="h1" weight="normal" size="base">
                                    Drag and Drop File here or
                                  </TXDText>
                                )}
                                <TXDText
                                  as="h1"
                                  weight="semibold"
                                  size="base"
                                  className="underline"
                                >
                                  Choose File
                                </TXDText>
                              </>
                            )}
                          </TXDFlex>
                        </TXDFlex>
                      </TXDBox>
                    </TXDFlex>
                  </TXDFlex>
                </TXDBox>
              </TXDFlex>
              <TXDFlex
                className="px-8 flex justify-between mt-4 mb-6"
                alignItems="center"
              >
                <TXDText
                  align="left"
                  as="h1"
                  className="text-gray-500"
                  size="xs"
                  weight="bold"
                >
                  Supported formats: .CSV, .XLS, .XLSX
                </TXDText>
                <TXDButton
                  className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                  radius="full"
                  variant="primary"
                  type="submit"
                  disabled={loading || !selectedFile}
                >
                  {/* <TXDButton
                  className="bg-white text-[#00AFD8] uppercase py-1 w-auto shadow-sm ring-1 ring-[#00AFD8] hover:bg-[#00AFD8] hover:text-white hover:ring-[#00AFD8]"
                  size="sm"
                  variant="primary"
                  disabled={loading || !selectedFile}
                  type="submit"
                > */}
                  {loading ? <SaveSpinner /> : "Upload"}
                </TXDButton>
              </TXDFlex>
            </form>
          </TXDBox>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default UploadFileModal;
