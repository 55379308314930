import React from "react";
import { TXDBox } from "@supporttxend/txd-components";
import { ITableWrapper } from "@/interfaces";

const TableWrapper: React.FC<ITableWrapper> = ({ children }) => {
  return (
    <>
      <TXDBox className="shadow-lg bg-white  mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
        <TXDBox className="overflow-x-auto">{children}</TXDBox>
      </TXDBox>
    </>
  );
};

export default TableWrapper;
