import React, { useState } from "react";
import {
  TXDBox,
  TXDText,
  TXDFlex,
  TXDImage,
  TXDButton,
} from "@supporttxend/txd-components";
import { useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faPen } from "@fortawesome/free-solid-svg-icons";
import ProfileSkeleton from "@/layouts/skeletons/ProfileSkeleton";
import profileImage from "../assets/images/profile.png";
import EditParticipantProfileIndividualModal from "@/layouts/modals/EditParticipantProfileIndividualModal";
import { IIndividualParticipantProps } from "@/interfaces";
import useFetchProfile from "@/services/userProfile";

const ParticipantsDetail: React.FC<IIndividualParticipantProps> = ({
  componentPermissions,
}) => {
  const { permissionsList } = componentPermissions;
  const [open, setOpen] = useState<boolean>(false);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return <div>Invalid ID</div>;
  }

  const { userProfile } = useFetchProfile(id);

  if (!userProfile) {
    return (
      <TXDFlex
        variant="column"
        className="shadow-sm mx-4 mt-10  sm:mx-6  md:mx-8 lg:mx-12 xl:mx-32 bg-white z-1 h-auto"
      >
        <ProfileSkeleton />
      </TXDFlex>
    );
  }

  const InternalPortfolio = userProfile.InternalPortfolio;

  const formatPhoneNumber = (phoneNumber: string | any[]) => {
    return phoneNumber
      ? `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6)}`
      : "N/A";
  };

  const formatSocialSecurity = (ssn: string) => {
    if (ssn.length >= 4) {
      return `***_-${ssn.slice(-4)}`;
    }
    return ssn;
  };
  const handleEditClick = (portfolioPermId: number) => {
    setCurrentPortfolioPermId(portfolioPermId);
    setOpen(true);
  };

  const formattedSSN = formatSocialSecurity(
    InternalPortfolio.PortfolioTaxIdentifier
  );
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };
  const formattedDOB = formatDate(userProfile.Portfolio.DOB);
  const dataArray = [
    {
      key: "TOTAL ASSETS",
      value: `$${InternalPortfolio?.PortfolioAssetsLineage.toLocaleString()}`,
    },
  ];

  const toggleInfoVisibility = () => {
    setInfoVisible(!isInfoVisible);
  };
  const accordionClasses = isInfoVisible
    ? "max-h-96 transition-all ease-out duration-500 opacity-100 visible"
    : "max-h-0 transition-all ease-out duration-500 opacity-0 invisible";

  return (
    <>
      {permissionsList.write ? (
        <TXDFlex
          variant="column"
          className="shadow-lg mx-4 mt-10  sm:mx-6 md:mx-8 lg:mx-12 xl:mx-32 bg-white z-1 h-auto"
        >
          <TXDFlex className="company-profile border-b-[#f9f9f9] border-b-2">
            <TXDText
              align="left"
              as="h1"
              className="uppercase w-4/5 border-l-4 rounded-tr-md border-l-[#4EC6E5]  py-4 pl-4 pr-20 font-sm text-gray-500 sm:text-2xl"
              size="2xl"
              weight="normal"
            >
              PARTICIPANT DETAIL
            </TXDText>
          </TXDFlex>
          <TXDBox className="border-b-[#f9f9f9] border-b-2 grid grid-cols-12 px-6 sm:px-10  sm:gap-14 gap-0">
            <TXDBox className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-3">
              <TXDFlex variant="row" className="py-4 px-2 sm:px-12 lg:px-6 ">
                <TXDFlex justifyContent="center" alignItems="center">
                  <TXDImage
                    display="inline-block"
                    radius="full"
                    size="xss"
                    src={profileImage}
                    variant="cover"
                    className="h-18 w-20 z-1"
                  />
                  <TXDText
                    as="span"
                    className="text-gray-500 whitespace-nowrap sm:text-xl  md:text-xl  lg:text-xl 2xl:text-2xl text-2xl"
                    size="2xl"
                    weight="normal"
                  >
                    {`${userProfile?.InternalPortfolio?.PortfolioFirstName} ${userProfile?.InternalPortfolio?.PortfolioLastName}`}
                  </TXDText>
                </TXDFlex>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="pb-3 border-b-2 gap-2 sm:gap-1 border-b-[#f9f9f9] lg:border-b-0 lg:pb-0 grid lg:grid-cols-2 xl:grid-cols-2 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6 xl:col-span-6   ">
              {dataArray.map((data, index) => (
                <TXDFlex
                  key={index}
                  className={`px-2 sm:px-12 lg:px-0 lg:justify-center ${
                    data.key === "DAILY CHANGE IN VALUE" ? "pl-2 lg:pl-8" : ""
                  }`}
                >
                  <TXDBox className="grid grid-cols-2 lg:grid-cols-1 w-full">
                    <TXDBox className="flex items-center lg:items-end">
                      <TXDText
                        as="span"
                        className="text-gray-900 uppercase font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                        size="xs"
                      >
                        {data.key}
                        {data.key === "DAILY CHANGE IN VALUE" && (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            className="ml-2 text-[#22C55E]"
                          />
                        )}
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="flex item-center lg:items-start">
                      <TXDText
                        as="span"
                        className="text-gray-500 text-[14px] sm:text-[16px] md:text-lg lg:text-xl xl:text-2xl"
                        weight="normal"
                      >
                        {data.value}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                </TXDFlex>
              ))}
            </TXDBox>
            <TXDBox className="gap-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 px-4 py-4 xl:py-0 lg:py-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3 xl:col-span-3 ">
              <TXDFlex
                variant="row"
                alignItems="center"
                className="justify-center lg:justify-end"
              >
                <TXDText
                  as="span"
                  className="text-gray-400 uppercase text-[10px] sm:text-[12px]"
                  weight="bold"
                >
                  Identifier:
                </TXDText>
                <TXDText
                  as="span"
                  className="text-gray-400 uppercase text-[10px] sm:text-[12px]"
                  weight="bold"
                >
                  {userProfile.InternalPortfolio.PortfolioPermId}
                </TXDText>
              </TXDFlex>
              <TXDFlex
                variant="row"
                alignItems="center"
                className="justify-center lg:justify-end"
              >
                <TXDButton
                  className="bg-[#FFFFFF] font-bold uppercase text-[10px] sm:text-xs py-1 px-1 text-gray-400 shadow-sm rounded ring-gray-200 hover:bg-gray-500 hover:text-white hover:ring-gray-500"
                  id="addcompanyinfo"
                  onClick={toggleInfoVisibility}
                >
                  {isInfoVisible ? "Hide" : "Show"} Participant Info
                </TXDButton>
              </TXDFlex>
            </TXDBox>
          </TXDBox>
          <TXDBox className={`${accordionClasses} `}>
            {isInfoVisible && (
              <TXDFlex
                justifyContent="center"
                className="mt-8 gap-6 sm:gap-16  flex-wrap "
              >
                <TXDBox className=" pb-2 sm:pb-12  ">
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        SOCIAL SECURITY
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {formattedSSN}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        DATE OF BIRTH
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {formattedDOB}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        MARITAL STATUS
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        Married
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                </TXDBox>

                {/* second */}

                <TXDBox className="pb-2 sm:pb-12">
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        PHONE NUMBER
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {formatPhoneNumber(
                          userProfile.InternalPortfolio.PortfolioPhone
                        )}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        EMAIL ADDRESS
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {userProfile.InternalPortfolio.PortfolioEmailAddress1}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        SKYPE ID
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        JaneThompson1992
                      </TXDText>
                    </TXDBox>
                  </TXDBox>

                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        PHYSICAL ADDRESS
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {userProfile.InternalPortfolio.PortfolioAddress1}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>

                  <TXDBox className="grid grid-cols-2 gap-1">
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900 uppercase"
                        size="xs"
                        weight="medium"
                      >
                        CITY, STATE. ZIP
                      </TXDText>
                    </TXDBox>
                    <TXDBox className="p-2">
                      <TXDText
                        as="h4"
                        className="text-gray-900"
                        size="xs"
                        weight="normal"
                      >
                        {` ${userProfile.InternalPortfolio.PortfolioCity},  ${userProfile.InternalPortfolio.PortfolioStateProv} ${userProfile.InternalPortfolio.PortfolioPostalCode}  `}
                      </TXDText>
                    </TXDBox>
                  </TXDBox>
                </TXDBox>
                {/*third  */}
                <TXDBox className="pb-6 sm:pb-12 sm:pl-12 ">
                  {permissionsList.update ? (
                    <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
                      <FontAwesomeIcon
                        className="border  text-sm cursor-pointer text-gray-400 border-gray-400 p-1 rounded-full"
                        icon={faPen}
                        onClick={() =>
                          handleEditClick(
                            userProfile.InternalPortfolio.PortfolioPermId
                          )
                        }
                      />
                    </TXDBox>
                  ) : (
                    <></>
                  )}
                </TXDBox>
              </TXDFlex>
            )}
          </TXDBox>
          {open && (
            <EditParticipantProfileIndividualModal
              modalOpen={open}
              closeModal={() => setOpen(false)}
              userProfile={{ ...userProfile }}
              portfolioPermId={currentPortfolioPermId}
            />
          )}
        </TXDFlex>
      ) : (
        <></>
      )}
    </>
  );
};

export default ParticipantsDetail;
