import React from "react";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDImage,
  TXDText,
} from "@supporttxend/txd-components";
import rollOverLogo from "../assets/images/login-rollover-logo.png";

import { Link } from "react-router-dom";
import { resetRegistration } from "@/redux/slices/registrationSlice";
import { AppDispatch } from "@/redux/store";
import { useDispatch } from "react-redux";

const SignupNavbar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const resetRegistrationRedux = () => {
    dispatch(resetRegistration());
  };
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <TXDBox className="mx-auto   px-4 sm:px-6 lg:px-8  ">
            <TXDFlex
              className="flex h-24  xl:justify-evenly  justify-between 
 "
            >
              <TXDFlex className="flex flex-shrink-0  items-center">
                <Link to="/">
                  <TXDImage
                    src={rollOverLogo}
                    alt="save-daily-logo"
                    className="w-auto h-8 sm:h-12 md:h-12 lg:h-12 xl:h-12 2xl:h-12 "
                  />
                </Link>
              </TXDFlex>

              <TXDFlex className="hidden xl:flex space-x-8 gap-4">
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-[#52A546] px-1 py-10  text-gray-900"
                >
                  <TXDText size="sm" weight="medium">
                    Safe Harbor
                  </TXDText>
                  <TXDText className="text-center" size="sm" weight="medium">
                    Rollovers
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-transparent px-1 py-10 text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <TXDText size="sm" weight="medium">
                    Professionals
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-transparent px-1 py-10  text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <TXDText size="sm" weight="medium">
                    Uncashed
                  </TXDText>
                  <TXDText className="text-center" size="sm" weight="medium">
                    Checks
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-transparent px-1 py-10  text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <TXDText size="sm" weight="medium">
                    {" "}
                    Benefits Election
                  </TXDText>
                  <TXDText className="text-center" size="sm" weight="medium">
                    Notifications
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-transparent px-1 py-10 text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <TXDText size="sm" weight="medium">
                    Individuals
                  </TXDText>
                </Link>
                <Link
                  to="#"
                  className="inline-flex flex-col items-center border-b-2 border-transparent px-1 py-10  text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                  <TXDText size="sm" weight="medium">
                    About us
                  </TXDText>
                </Link>
                <Link to="/start-rollover">
                  <TXDButton
                    className=" bg-[#52A546]  mt-8  text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 
            hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg"
                    radius="full"
                    size="sm"
                    variant="secondary"
                    border={false}
                    onClick={resetRegistrationRedux}
                  >
                    Registration
                  </TXDButton>
                </Link>
              </TXDFlex>
              <TXDFlex className="-mr-2 flex items-center xl:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#52A546]">
                  {open ? (
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="block h-6 w-6 "
                      aria-hidden="true"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faBars}
                      className="block h-6 w-6 "
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </TXDFlex>
            </TXDFlex>
          </TXDBox>

          <Disclosure.Panel className="  xl:hidden">
            <TXDBox className="space-y-1 pb-3 pt-2 flex flex-col">
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-[#52A546] bg-[#d7ead9] py-2 pl-3 pr-4 text-base font-medium text-[#47ab37]"
              >
                Automatic Rollovers
              </Disclosure.Button>

              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Terminated Plans
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Uncashed Checks
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Notifications Packages
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Voluntary IRA Accounts
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Abandoned Plans
              </Disclosure.Button>
            </TXDBox>
            <TXDBox className="border-t border-gray-200 pb-3 pt-3">
              <TXDBox className="flex items-center px-4">
                <Link to="/start-rollover">
                  <TXDButton
                    className=" bg-[#52A546]   text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 
            hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg"
                    radius="full"
                    size="sm"
                    variant="secondary"
                    border={false}
                  >
                    Registration
                  </TXDButton>
                </Link>
              </TXDBox>
            </TXDBox>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default SignupNavbar;
