import React, { useEffect, useState } from "react";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
} from "@supporttxend/txd-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import toast from "react-hot-toast";
import { USER_RESET_PASSWORD } from "@/services/api/userService";
import SaveSpinner from "@/layouts/skeletons/SaveSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";
const ResetPassword: React.FC = () => {
  const [passwordError, setPasswordError] = useState("");
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [retypePasswordTouched, setRetypePasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRetypePassword, setShowPasswordRetypePassword] =
    useState<boolean>(false);
  const [otp, setOtpState] = useState(new Array(6).fill(""));
  const referenceValue = useSelector(
    (state: RootState) => state.otp.referenceValue
  );

  const username = useSelector((state: RootState) => state.otp.username);
  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return;
    setOtpState([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
  });

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const password = e.target[7].value;
    const newPassword = e.target[8].value;
    console.table({
      password,
      newPassword,
    });
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError || passwordError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
      setLoading(false);
      return;
    } else {
      const otpString = otp.join("");
      toast
        .promise(
          USER_RESET_PASSWORD(username, newPassword, otpString, referenceValue),
          {
            loading: LOADING_MESSAGES.RESET_LOADING,
            success: <b>{SUCCESS_MESSAGES.PASSWORD_RESETED_SUCC}</b>,
            error: <b>{ERROR_MESSAGES.PASSWORD_RESET_FAILED}</b>,
          }
        )
        .then(() => {
          setLoading(false);
          navigate("/sign-in");
        })
        .catch((error: any) => {
          toast.error(ERROR_MESSAGES.PASSWORD_RESET_FAILED || error.message);
          setLoading(false);
        });
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  const handlePasswordChange = (field: string) => {
    const passwordInput = document.getElementById(
      "password"
    ) as HTMLInputElement;
    const retypePasswordInput = document.getElementById(
      "newPassword"
    ) as HTMLInputElement;

    if (field === "password") {
      setPasswordTouched(true);
    } else if (field === "newPassword") {
      setRetypePasswordTouched(true);
    }

    if (passwordTouched && retypePasswordTouched) {
      const password = passwordInput.value;
      const retypePassword = retypePasswordInput.value;

      if (password !== retypePassword) {
        setPasswordError("Passwords do not match.");
      } else {
        setPasswordError("");
      }
    }
  };
  useEffect(() => {
    const form = document.getElementById("otp-form") as HTMLFormElement;
    if (!form) return;

    const inputs = Array.from(
      form.querySelectorAll("input[type=text]")
    ) as HTMLInputElement[];
    const submit = form.querySelector(
      "button[type=submit]"
    ) as HTMLButtonElement;

    const handleKeyDown = (e: KeyboardEvent) => {
      const target = e.target as HTMLInputElement;
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputs.indexOf(target);
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const index = inputs.indexOf(target);
      if (target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit?.focus();
        }
      }
    };

    const handleFocus = (e: FocusEvent) => {
      const target = e.target as HTMLInputElement;
      target.select();
    };

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData?.getData("text") || "";
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, index) => (input.value = digits[index]));
      submit?.focus();
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handleInput);
        input.removeEventListener("keydown", handleKeyDown);
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("paste", handlePaste);
      });
    };
  }, []);
  return (
    <>
      <TXDFlex
        variant="column"
        justifyContent="center"
        className="flex min-h-full flex-1 px-6 lg:px-8 bg-[#FAFAFA]"
      >
        <TXDBox className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg shadow-lg p-10 mt-12">
          <TXDText
            as="span"
            align="center"
            size="xl"
            weight="bold"
            className="text-center flex justify-center py-4  text-gray-900"
          >
            Reset Password
          </TXDText>
          <TXDText
            as="span"
            size="xs"
            weight="normal"
            className="flex text-center  text-gray-900 pb-4"
          >
            Enter the 6-digit verification code that was sent to your mobile
            phone or email.
          </TXDText>
          <form className="space-y-6" id="otp-form" onSubmit={handleSubmit}>
            <TXDBox>
              <TXDFlex className="flex items-center justify-center gap-3">
                {otp.map((data, index) => (
                  <input
                    key={index}
                    maxLength={1}
                    className="w-14 h-14 text-center text-2xl font-medium text-slate-900 bg-slate-100 border border-transparent  appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#4EC6E5]"
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    required
                  />
                ))}
              </TXDFlex>
            </TXDBox>
            <TXDBox className="relative h-[88px]">
              <TXDInput
                id="password"
                name="password"
                label
                type={showPassword ? "text" : "password"}
                autoComplete="text"
                labelText="Password"
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                className="pl-0 placeholder:text-sm"
                required
                variant={"styled"}
                regex={REGEX_CONSTANTS.PASSWORD_REGEX}
                minLength={8}
                maxLength={30}
                placeholder="Enter New Password"
                onInput={() => handlePasswordChange("password")}
                errorExist={(error) =>
                  updateErrorState("input7", error ? true : false)
                }
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setShowPassword((prev) => !prev)}
                data-testid="toggle-password-visibility"
              >
                <FontAwesomeIcon
                  icon={showPassword ? faEye : faEyeSlash}
                  className="text-[#A2A2A2]"
                />
              </button>
            </TXDBox>
            <TXDBox className="relative h-[88px]">
              <TXDInput
                id="newPassword"
                name="newPassword"
                label
                type={showRetypePassword ? "text" : "password"}
                autoComplete="text"
                labelText="Confirm New Password"
                labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                className="pl-0 placeholder:text-sm"
                required
                variant={"styled"}
                placeholder="Confirm New Password"
                onInput={() => handlePasswordChange("newPassword")}
                errorExist={(error) =>
                  updateErrorState("input8", error ? true : false)
                }
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={() => setShowPasswordRetypePassword((prev) => !prev)}
                data-testid="toggle-password-visibility"
              >
                <FontAwesomeIcon
                  icon={showRetypePassword ? faEye : faEyeSlash}
                  className="text-[#A2A2A2]"
                />
              </button>
              {passwordError && (
                <TXDText className="absolute bottom-[-20px] left-0 text-red-500 text-sm font-normal">
                  {passwordError}
                </TXDText>
              )}
            </TXDBox>

            <TXDBox className="text-center">
              <TXDButton
                className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] "
                radius="full"
                variant="primary"
                type="submit"
              >
                {loading ? <SaveSpinner /> : "Reset Password"}
              </TXDButton>
            </TXDBox>
          </form>
        </TXDBox>
      </TXDFlex>
    </>
  );
};

export default ResetPassword;
