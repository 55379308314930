import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { TXDBox, TXDText, TXDFlex } from "@supporttxend/txd-components";
import MainInformationStepper from "./MainInformationStepper";
import ReviewStepper from "./ReviewStepper";
import {
  IAddParticipantModalProps,
  IStep,
  IMainInformationStepperProps,
} from "@/interfaces";
import { resetForm } from "@/redux/slices/participantFormSlice";
import { AppDispatch } from "@/redux/store";
import { useDispatch } from "react-redux";

const MainInformationStepperWrapper: React.FC<IMainInformationStepperProps> = ({
  closeModal,
  modalOpen,
}) => {
  return (
    <MainInformationStepper closeModal={closeModal} modalOpen={modalOpen} />
  );
};

const steps: IStep[] = [
  {
    id: "Step 1",
    name: "Main Information",
    component: MainInformationStepperWrapper,
  },
  // { id: "Step 2", name: "Asset Allocation", component: AssetAllocationStepper },
  { id: "Step 2", name: "Review", component: ReviewStepper },
];

const AddNewParticipantModal: React.FC<IAddParticipantModalProps> = ({
  modalOpen,
  closeModal,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [currentStep, setCurrentStep] = useState(0);

  const goToStep = (stepIndex: number) => {
    setCurrentStep(stepIndex);
  };

  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center "
          onClose={() => {
            //Do nothing
          }}
          data-testid="addNewParticipantModal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <TXDBox className="inline-block w-full max-w-5xl mx-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex
                justifyContent="center"
                className="participant-table "
                alignItems="center"
                style={{ maxHeight: "100vh", overflowY: "auto" }}
              >
                <TXDBox className="modalOverlay w-full overflow-y-scroll">
                  <TXDFlex
                    className="p-4 flex justify-between border-b"
                    alignItems="center"
                  >
                    <TXDText
                      align="left"
                      as="h1"
                      className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap  "
                      size="xl"
                      weight="normal"
                    >
                      ADD NEW PARTICIPANT
                    </TXDText>
                    <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                      <FontAwesomeIcon
                        icon={faTimes}
                        className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                        onClick={() => {
                          dispatch(resetForm());
                          closeModal();
                        }}
                      />
                    </TXDBox>
                  </TXDFlex>

                  <TXDFlex
                    variant="column"
                    className=" md:flex-row md:text-center justify-between bg-gray-100 py-4 px-4"
                  >
                    <TXDFlex
                      alignItems="center"
                      justifyContent="center"
                      className="  sm:justify-start gap-4 mb-2 md:mb-0"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase text-gray-500"
                        size="sm"
                        weight="normal"
                      >
                        {/* For Company */}
                      </TXDText>
                      <TXDText
                        as="h1"
                        className=" pl-4 text-gray-400"
                        size="sm"
                        weight="normal"
                      >
                        {/* Globex, Inc */}
                      </TXDText>
                    </TXDFlex>
                  </TXDFlex>
                  <nav aria-label="Progress" className="relative mt-4 px-4 ">
                    <ol
                      role="list"
                      className="relative space-y-4 sm:flex md:space-x-4 md:space-y-0 flex sm:flex-row flex-row justify-between"
                    >
                      <TXDBox
                        className="absolute bottom-4 left-0 w-full h-[0.2rem] "
                        style={{ transition: "background-color 0.3s ease" }}
                      >
                        {steps.map((step, index) => (
                          <TXDBox
                            key={step.id}
                            className="h-full"
                            style={{
                              width: `${100 / steps.length}%`,
                              backgroundColor:
                                index === currentStep ? "#6ea7eb" : "#D3D3D3",
                              transition: "left 0.3s ease",
                              borderRadius: index === currentStep ? "1px" : "0",
                              display: "inline-block",
                            }}
                          />
                        ))}
                      </TXDBox>

                      {steps.map((step, index) => (
                        <li
                          key={step.id}
                          className={`sm:flex-1  cursor-pointer  ${
                            index === currentStep
                              ? "text-[#4EC6E5]"
                              : "text-gray-500"
                          }`}
                          onClick={() => goToStep(index)}
                        >
                          <TXDFlex variant="column">
                            <TXDText
                              as="span"
                              size="xs"
                              weight="bold"
                              className={`sm:ml-8 ml-0 uppercase ${
                                index === currentStep
                                  ? "text-[#4EC6E5]"
                                  : "text-gray-500"
                              }`}
                            >
                              {step.id}
                            </TXDText>
                            <TXDText
                              as="span"
                              size="xl"
                              weight="normal"
                              className={`sm:ml-8 ml-0 pb-4 ${
                                index === currentStep
                                  ? "text-[#4EC6E5]"
                                  : "text-gray-500"
                              } sm:text-xl text-sm`}
                            >
                              {step.name}
                            </TXDText>
                          </TXDFlex>
                        </li>
                      ))}
                    </ol>
                  </nav>

                  <TXDBox
                    style={{ maxHeight: "70vh", overflowY: "auto" }}
                    className="participant-table pb-6"
                  >
                    {steps.map((step, index) => (
                      <Fragment key={step.id}>
                        {index === currentStep &&
                          React.createElement(
                            step.component,
                            index === 0 ? { closeModal } : {}
                          )}
                      </Fragment>
                    ))}
                  </TXDBox>
                </TXDBox>
              </TXDFlex>
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddNewParticipantModal;
