/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDText,
  TXDSelect,
  TXDImage,
  TXDCheckbox,
} from "@supporttxend/txd-components";
import curveSvg from "../../../assets/svg/curve.svg";
import { useNavigate } from "react-router";
import { AppDispatch, RootState } from "@/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { resetRegistration, setStep } from "@/redux/slices/registrationSlice";
import {
  CHECK_USER_NAME,
  GET_ACCESS_CODE,
  INTERNAL_SPONSOR_ADD_RQ,
} from "@/services/api/userService";
import LoginSpinner from "@/layouts/skeletons/LoginSpinner";
import { Link } from "react-router-dom";
import { BUSINESS_TYPES, STATE_PROVINCE } from "@/constants/enumConstants";
import { validateAge } from "@/utils/componentUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";
import { API_BODY_CONSTANTS } from "@/constants/constant";

const TPACreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [localStreetAddress, setLocalStreetAddress] = useState("");
  const [localStreetAddress2, setLocalStreetAddress2] = useState("");
  const [localCity, setLocalCity] = useState("");
  const [localState, setLocalState] = useState("");
  const [localZipCode, setLocalZipCode] = useState("");
  const [showArea, setShowArea] = useState(false);
  const getEnteredAddress = () => {
    const addressLine2 = localStreetAddress2 ? `, ${localStreetAddress2}` : "";
    return `${localStreetAddress}${addressLine2}, ${localCity}, ${localState} ${localZipCode}`;
  };

  const getPostalRecommendation = () => {
    return `${localStreetAddress}, ${localCity}, ${localState} ${localZipCode}`;
  };

  const [enteredAddress, setEnteredAddress] = useState(getEnteredAddress());
  const [postalRecommendation, setPostalRecommendation] = useState(
    getPostalRecommendation()
  );

  useEffect(() => {
    setEnteredAddress(getEnteredAddress());
    setPostalRecommendation(getPostalRecommendation());
  }, [
    localStreetAddress,
    localStreetAddress2,
    localCity,
    localState,
    localZipCode,
  ]);
  const handleBlur = (field: string, value: string) => {
    // dispatch(updateFormField({ field, value }));
  };
  const handleInputChange = (value: any, setter: (arg0: any) => void) => {
    setter(value);
  };
  const { firstName, lastName, userName, password, email, middleName } =
    useSelector((state: RootState) => state.registrationForm);

  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const adminstrativefirm = e.target[0].value;
    const streetaddress = e.target[1].value;
    const streetaddress2 = e.target[2].value;
    const city = e.target[3].value;
    const stateprovince = e.target[4].value;
    const cphonenumber = e.target[7].value;
    const zipCode = e.target[9].value;

    const formattedPhone = cphonenumber.replace(/\D/g, "");
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.ERROR_SUBMIT);
      setLoading(false);
      return;
    }
    try {
      const apiData = {
        UserName: userName,
        EmailAddr: email,
        PortfolioPassword: password,
        Phone: formattedPhone,
        PhoneType: formattedPhone.length,
        FirstName: firstName,
        MiddleName: middleName,
        LastName: lastName,
        LegalName: adminstrativefirm
          ? adminstrativefirm
          : `${firstName} ${lastName}`,
        Addr1: streetaddress,
        Addr2: streetaddress2,
        City: city,
        StateProv: stateprovince,
        PostalCode: zipCode,
        ReferValue: API_BODY_CONSTANTS.REFER_VALUE,
        Options: API_BODY_CONSTANTS.OPTIONS,
        Status: API_BODY_CONSTANTS.REGISTER_PORTFOLIO_STATUS,
      };
      // console.log(apiData);
      const userNameCheck = await CHECK_USER_NAME(userName);
      if (userNameCheck) {
        throw new Error(ERROR_MESSAGES.USERNAME_TAKEN);
      }
      const accessCode = await GET_ACCESS_CODE();
      if (!accessCode) {
        throw new Error(ERROR_MESSAGES.ACCESS_CODE_FAILURE);
      }

      const response = await INTERNAL_SPONSOR_ADD_RQ(apiData, accessCode);
      if (response && response.PortfolioAddRs) {
        toast.success(SUCCESS_MESSAGES.ACCOUNT_CREATED);
        dispatch(resetRegistration());
        navigate("/sign-in");
      } else {
        throw new Error(ERROR_MESSAGES.INVALID_SERVER_RESPONSE);
      }
    } catch (error) {
      toast.error(ERROR_MESSAGES.ACCOUNT_CREATE_FAILED || error);
    } finally {
      setLoading(false);
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validateAge(event.target.value);
    setDateError(errorMessage);
    updateErrorState("dob", !!errorMessage);
  };
  return (
    <TXDFlex
      justifyContent="center"
      alignItems="center"
      className="relative px-4"
      variant="column"
    >
      <TXDText
        as="h1"
        size="2xl"
        weight="medium"
        className="text-[#333333] mt-16 mb-8"
      >
        Plan Sponosrs - Create an Account
      </TXDText>
      <TXDFlex className="flex-col w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-[428px] relative z-10 gap-2">
        <form onSubmit={handleSubmit}>
          <TXDBox>
            <TXDInput
              id="adminstrativefirm"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Company Name (PS)"}
              radius="md"
              sizing="xs"
              minLength={0}
              maxLength={30}
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.ADMINISTRATIVE_FIRM_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input1", error ? true : false)
              }
            />
          </TXDBox>
          {/*  */}
          <TXDBox>
            <TXDInput
              id="streetaddress"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Street Address"}
              radius="md"
              sizing="xs"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              required
              errorExist={(error: any) =>
                updateErrorState("input3", error ? true : false)
              }
              onInput={(e: any) => setLocalStreetAddress(e.target.value)}
              onBlur={() => handleBlur("streetaddress", localStreetAddress)}
            />
            <TXDText size="xs">(Cannot be a P.O. Box)</TXDText>
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="streetaddress2"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Street Address 2 (Optional)"}
              radius="md"
              sizing="xs"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              errorExist={(error: any) =>
                updateErrorState("input4", error ? true : false)
              }
              // onInput={(e: any) => setLocalStreetAddress2(e.target.value)}
              // onBlur={() => handleBlur("streetaddress2", localStreetAddress2)}
            />
          </TXDBox>
          <TXDFlex className="justify-between w-full" alignItems="center">
            <TXDBox className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <TXDBox>
                <TXDInput
                  defaultValue=""
                  id="selectCity"
                  label
                  type="text"
                  labelClass="font-bold text-[#A2A2A2] text-xs"
                  variant="regular"
                  className="focus:ring-0 focus:ring-transparent border border-gray-200"
                  labelText={"City"}
                  sizing="xs"
                  minLength={2}
                  maxLength={30}
                  regex={REGEX_CONSTANTS.CITY_REGEX}
                  errorExist={(error: any) =>
                    updateErrorState("input7", error ? true : false)
                  }
                  onInput={(e: any) => setLocalCity(e.target.value)}
                  onBlur={() => handleBlur("selectCity", localCity)}
                  required
                />
              </TXDBox>
              <TXDBox>
                <TXDSelect
                  defaultValue=""
                  id="selectState"
                  isObjectArray
                  label
                  labelClass="font-bold text-[#A2A2A2] text-xs"
                  variant="regular"
                  className="focus:ring-0 focus:ring-transparent border border-gray-200 select-nonoverlap"
                  labelText={"State or Province"}
                  optionsArray={STATE_PROVINCE["US"].map((state) => ({
                    label: state.name || state.label,
                    value: state.state_code || state.value,
                    disabled: state.disabled,
                  }))}
                  onChange={(e) =>
                    handleInputChange(e.target.value, setLocalState)
                  }
                  sizing="xs"
                  required
                />
              </TXDBox>
            </TXDBox>
          </TXDFlex>

          <TXDFlex className="justify-between w-full" alignItems="center">
            <TXDBox className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <TXDBox>
                <TXDInput
                  id="fname"
                  label
                  labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
                  labelText="First Name"
                  radius="md"
                  sizing="xs"
                  type="text"
                  variant="regular"
                  minLength={1}
                  maxLength={30}
                  className="focus:ring-0 focus:ring-transparent"
                  regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
                  required
                  errorExist={(error: any) =>
                    updateErrorState("input9", error ? true : false)
                  }
                  initialValue={firstName}
                  disabled
                  filled
                />
              </TXDBox>
              <TXDBox>
                <TXDInput
                  id="lname"
                  label
                  labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
                  labelText="Last Name"
                  radius="md"
                  sizing="xs"
                  type="text"
                  variant="regular"
                  minLength={1}
                  maxLength={30}
                  className="focus:ring-0 focus:ring-transparent"
                  regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
                  required
                  errorExist={(error: any) =>
                    updateErrorState("input10", error ? true : false)
                  }
                  initialValue={lastName}
                  disabled
                  filled
                />
              </TXDBox>
            </TXDBox>
          </TXDFlex>
          <TXDFlex className="justify-between w-full" alignItems="center">
            <TXDBox className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <TXDBox>
                <TXDInput
                  id="cphonenumber"
                  label
                  labelClass="font-bold text-[#A2A2A2] text-xs"
                  labelText={"Contact Phone Number"}
                  radius="md"
                  sizing="xs"
                  type="tel"
                  variant="regular"
                  className="focus:ring-0 focus:ring-transparent"
                  regex={REGEX_CONSTANTS.PHONE_REGEX}
                  required
                  errorExist={(error: any) =>
                    updateErrorState("input11", error ? true : false)
                  }
                />
              </TXDBox>
              <TXDBox>
                <TXDInput
                  id="email"
                  label
                  labelClass="font-bold text-[#A2A2A2] text-xs"
                  labelText={"Contact Email Address"}
                  radius="md"
                  sizing="xs"
                  type="email"
                  variant="regular"
                  className="focus:ring-0 focus:ring-transparent"
                  regex={REGEX_CONSTANTS.EMAIL_REGEX}
                  required
                  errorExist={(error: any) =>
                    updateErrorState("input12", error ? true : false)
                  }
                  initialValue={email}
                  disabled
                  filled
                />
              </TXDBox>
            </TXDBox>
          </TXDFlex>

          <TXDBox>
            <TXDInput
              id="zipCode"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Zip Code (Only 5 digits)"}
              radius="md"
              sizing="xs"
              required
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input16", error ? true : false)
              }
              onInput={(e: any) => setLocalZipCode(e.target.value)}
              onBlur={() => handleBlur("zipCode", localZipCode)}
            />
          </TXDBox>
          <TXDBox hidden>
            <TXDInput
              id="nameSuffix"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              className="focus:ring-0 focus:ring-transparent"
              labelText="Name Suffix"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              errorExist={(error: any) =>
                updateErrorState("input17", error ? true : false)
              }
              initialValue={""}
            />
          </TXDBox>
          <TXDFlex className="mt-3">
            <TXDCheckbox
              checkboxArray={[
                {
                  id: "precommendations",
                  value: "precommendations",
                },
              ]}
              inline
              isObjectArray
              labelClass="leading-4 text-[12px] font-normal"
              className="h-4 w-4 rounded border-gray-300 text-[#00AFD8] focus:ring-0 outline-0 cursor-pointer"
              required
            />

            <TXDText
              as="label"
              htmlFor="Acknowledge"
              className="leading-4 text-[12px] font-normal cursor-pointer"
            >
              I acknowledge that I have read and reviewed the{" "}
              <span className="text-[#00AFD8]">Privacy statement</span> and the{" "}
              <span className="text-[#00AFD8]">Terms and Conditions</span> for
              use of this website.
            </TXDText>
          </TXDFlex>
          {showArea && (
            <>
              <TXDText
                weight="normal"
                className="leading-4 mt-4  w-auto absolute text-[17px] fon text-[#000000] font-normal	 left-1/2 transform -translate-x-1/2 z-10 whitespace-nowrap hidden sm:block"
              >
                The US Portal Service suggests the address displayed below.
                Please select the best address to use.
              </TXDText>
              <TXDFlex
                alignItems="center"
                variant="column"
                className="mt-14 sm:mt-14"
              >
                <label className="flex items-center w-11/12 overflow-hidden">
                  <input
                    type="radio"
                    name="addressOption"
                    value="entered"
                    className="mr-2 ml-1 text-[#00AFD8] focus:ring-[#00AFD8] cursor-pointer"
                    required
                  />
                  <span className="text-xs text-red-500 font-medium">
                    Entered Address:
                  </span>
                  &nbsp;
                  <span className="text-xs font-medium">{enteredAddress}</span>
                </label>
                <label className="flex items-center w-11/12 overflow-hidden">
                  <input
                    type="radio"
                    name="addressOption"
                    value="recommended"
                    className="mr-2 ml-1 text-[#00AFD8] focus:ring-[#00AFD8] cursor-pointer"
                    required
                  />
                  <span className="text-xs text-red-500 font-medium">
                    Postal Recommendation:
                  </span>
                  &nbsp;
                  <span className="text-xs font-medium">
                    {postalRecommendation}
                  </span>
                </label>
              </TXDFlex>
            </>
          )}
          <TXDFlex justifyContent="center" className="gap-[28px] mt-6">
            <Link to="/start-rollover">
              <TXDButton
                radius="md"
                size="sm"
                variant="primary"
                className="bg-[#757575] uppercase  py-2 text-xs md:text-xs lg:text-sm shadow-sm  ring-secondary-400 hover:bg-white hover:text-[#757575] hover:ring-1  w-[100px]"
              >
                CANCEL
              </TXDButton>
            </Link>
            <TXDButton
              radius="md"
              size="sm"
              variant="primary"
              className="bg-[#52A546] py-2 text-white text-xs md:text-xs lg:text-sm shadow-sm hover:bg-white hover:text-[#52A546] hover:ring-1 hover:ring-[#52A546] hover:shadow-lg w-[100px]"
              onClick={() => setShowArea(true)}
            >
              {loading ? <LoginSpinner /> : "NEXT"}
            </TXDButton>
          </TXDFlex>
        </form>
      </TXDFlex>

      <TXDImage
        src={curveSvg}
        className="absolute top-[410px] w-full h-[1024px] opacity-100 z-0"
      />
    </TXDFlex>
  );
};

export default TPACreateAccount;
