import { z } from "zod";

export const UploadParticipantBodySchema = z
  .object({
    PortfolioPermId: z.number({
      required_error: "PortfolioPermId is a required field",
      invalid_type_error: "PortfolioPermId should be a number value",
    }),
    AgentPermId: z.number({
      required_error: "AgentPermId is a required field",
      invalid_type_error: "AgentPermId should be a number value",
    }),
    FileName: z.string({
      required_error: "FileName is a required field",
      invalid_type_error: "FileName should be a string value",
    }),
    FileData: z
      .string({
        required_error: "FileData is a required field",
        invalid_type_error: "FileData should be a base64 value",
      })
      .base64(),
  })
  .strict();

export const ParticipantFileSchema = z
  .object({
    SponsorId: z.number({
      invalid_type_error: "Sponsor ID shold be a number",
      required_error: "Sponosr ID is a required field",
    }),
    FirstName: z.string({
      invalid_type_error: "First Name shold be a string value",
      required_error: "First Name is a required field",
    }),
    LastName: z.string({
      invalid_type_error: "Last Name shold be a string value",
      required_error: "Last Name is a required field",
    }),
    StreetAddress: z.string({
      invalid_type_error: "Street Address shold be a string value",
      required_error: "Street Address is a required field",
    }),
    City: z.string().optional(),
    State: z.string().optional(),
    Zip: z.number().optional(),
    DOB: z.any({
      required_error: "Date of birth is a required field",
    }),
    SSN: z.number({
      invalid_type_error: "Social Security Number shold be a number value",
      required_error: "Social Security Number is a required field",
    }),
    X: z.any().optional(),
  })
  .catchall(z.any())
  .strict();

export const ListParticipantFileDataSchema = z.array(ParticipantFileSchema);
