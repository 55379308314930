import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { TXDText, TXDBox, TXDFlex } from "@supporttxend/txd-components";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { MARTIAL_STATUS, STATUS_CODE_MAP } from "@/constants/enumConstants";

const ReviewStepper: React.FC = () => {
  const participantForm = useSelector(
    (state: RootState) => state.participantForm
  );

  return (
    <>
      <TXDBox className="mt-5  grid grid-cols-1 md:grid-cols-2 px-6">
        {/* 1st column */}

        {/* 2nd column */}
        <TXDFlex variant="column" className="">
          {/* Block 1 */}

          <TXDBox className="">
            <TXDText
              as="h4"
              className="text-gray-500 uppercase"
              size="xs"
              weight="medium"
            >
              MAIN INFORMATION
            </TXDText>
          </TXDBox>
          <TXDBox className="mt-5 space-y-2">
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs"
                size="sm"
                weight="normal"
              >
                Tax ID
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500 text-xs"
                size="sm"
                weight="normal"
              >
                {participantForm.taxId}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs"
                size="sm"
                weight="normal"
              >
                FIRST NAME
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.firstName}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs "
                size="sm"
                weight="normal"
              >
                LAST NAME
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.lastName}
              </TXDText>
            </TXDFlex>
            {/* <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400   text-xs  "
                size="sm"
                weight="normal"
              >
                SOCIAL SECURITY NUMBER:
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500   text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.socialSecurity}
              </TXDText>
            </TXDFlex> */}
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs   "
                size="sm"
                weight="normal"
              >
                BIRTH DATE:
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500 text-xs  "
                size="sm"
                weight="normal"
              >
                {participantForm.dob}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs    "
                size="sm"
                weight="normal"
              >
                MARRITAL STATUS
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs  "
                size="sm"
                weight="normal"
              >
                {MARTIAL_STATUS.find(
                  (status) => status.value === participantForm.legalStatus
                )?.name || participantForm.legalStatus}
              </TXDText>
            </TXDFlex>
            <hr className="!mt-3 !mb-3 border-gray-200" />
            <TXDText
              as="h2"
              className="text-gray-500  uppercase text-xs  "
              size="sm"
              weight="normal"
            >
              CONTACT INFORMATION
            </TXDText>
          </TXDBox>

          {/* Block 2 */}
          <TXDBox className="mt-5 space-y-2 ">
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs     "
                size="sm"
                weight="normal"
              >
                PHONE NUMBER
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500   text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.phoneNo}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs   "
                size="sm"
                weight="normal"
              >
                EMAIL ADDRESS
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500   text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.email}
              </TXDText>
            </TXDFlex>
            {/* <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400   text-xs "
                size="sm"
                weight="normal"
              >
                SKYPE ID
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500 text-xs     "
                size="sm"
                weight="normal"
              >
                {participantForm.skypeId}
              </TXDText>
            </TXDFlex> */}
            <hr className="!mt-3 !mb-3  border-gray-200	" />

            <TXDText
              as="h2"
              className="text-gray-500 text-xs "
              size="sm"
              weight="normal"
            >
              PHYSICAL ADDRESS
            </TXDText>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs "
                size="sm"
                weight="normal"
              >
                Street Address
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs   "
                size="sm"
                weight="normal"
              >
                {participantForm.streetAddress}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs "
                size="sm"
                weight="normal"
              >
                Street Address 2 (Optional)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs   "
                size="sm"
                weight="normal"
              >
                {participantForm.streetAddress2}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400 text-xs    "
                size="sm"
                weight="normal"
              >
                CITY
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500 text-xs    "
                size="sm"
                weight="normal"
              >
                {participantForm.city}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs  "
                size="sm"
                weight="normal"
              >
                STATE (OR PROVINCE)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500 text-xs   "
                size="sm"
                weight="normal"
              >
                {participantForm.stateProvinces}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs  "
                size="sm"
                weight="normal"
              >
                ZIP CODE (OR POSTAL CODE)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs  "
                size="sm"
                weight="normal"
              >
                {participantForm.zipCode}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className="justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs  "
                size="sm"
                weight="normal"
              >
                Profile Status
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs  "
                size="sm"
                weight="normal"
              >
                {STATUS_CODE_MAP[Number(participantForm.martialStatus)]}
              </TXDText>
            </TXDFlex>
            <hr className="!mt-3 !mb-3  border-gray-200	" />
          </TXDBox>

          {/* Block 3 */}

          <TXDText
            as="h2"
            className="text-gray-500 text-xs    "
            size="sm"
            weight="normal"
          >
            COMMUNICATION PREFERENCE
          </TXDText>

          <TXDBox className="mt-2 space-y-2">
            <TXDFlex alignItems="center" className=" justify-between gap-2">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs  "
                size="sm"
                weight="normal"
              >
                IRA AMOUNT
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.RothIRAAmount}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className=" justify-between gap-2 ">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs"
                size="sm"
                weight="normal"
              >
                TRADITIONAL IRA AMOUNT
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.TraditionalRothIRAAmount}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className=" justify-between gap-2 ">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs"
                size="sm"
                weight="normal"
              >
                EMPLOYEE COUNTRY (Optional)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.EmployeeCounrty}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className=" justify-between gap-2 ">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs"
                size="sm"
                weight="normal"
              >
                EMPLOYEE NAME (Optional)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.EmployeeName}
              </TXDText>
            </TXDFlex>
            <TXDFlex alignItems="center" className=" justify-between gap-2 ">
              <TXDText
                as="h2"
                className="text-gray-400  text-xs"
                size="sm"
                weight="normal"
              >
                COUNTRY (Optional)
              </TXDText>
              <TXDText
                as="h2"
                className="text-gray-500  text-xs "
                size="sm"
                weight="normal"
              >
                {participantForm.Country}
              </TXDText>
            </TXDFlex>
          </TXDBox>
        </TXDFlex>

        {/* 3rd column */}
        <TXDBox className="ml-0 mt-3 md:ml-10 flex">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="h-4 w-4 opacity-60 pr-2 text-blue-600"
          />
          <TXDText
            weight="normal"
            size="sm"
            as="p"
            className="text-gray-500 pb-2 md:pb-10 text-xs text-justify "
          >
            Ensure that the information about participant is correct. If you
            need to edit it, switch between the tabs above. If information is
            correct, click on the button Confirm and Send Email
          </TXDText>
        </TXDBox>
      </TXDBox>

      {/* <hr className=" border-slate-400		mx-3	 mt-10" />
      <TXDFlex
        alignItems="center"
        className="text-center  gap-2 p-3 px-10 cursor-pointer"
      >
        <FontAwesomeIcon
          icon={faCaretRight}
          className="h-4 w-4 opacity-60 text-gray-400"
        />
        <TXDText weight="normal" size="sm" className="text-gray-500 ">
          Asset Allocation
        </TXDText>
      </TXDFlex> */}

      {/* <hr className=" border-slate-400	mx-3 " />
      <TXDFlex
        alignItems="center"
        className=" text-center px-0 sm:px-10 mt-2 flex-col sm:flex-row"
      >
        <TXDButton className="bg-transparent hover:bg-transparent">
          <FontAwesomeIcon icon={faPrint} className="h-3 w-3  text-gray-400" />
          &nbsp;
          <TXDText weight="normal" size="sm" className="  text-gray-400 ">
            Print
          </TXDText>
        </TXDButton>

        <TXDButton className="bg-transparent hover:bg-transparent">
          <FontAwesomeIcon
            icon={faFilePdf}
            className="h-3 w-3  text-gray-400"
          />
          &nbsp;
          <TXDText weight="normal" size="sm" className="  text-gray-400 white">
            Save as PDF
          </TXDText>
        </TXDButton>
      </TXDFlex>
      <TXDFlex
        variant="column"
        justifyContent="center"
        className="  sm:flex-row  gap-7 sm:pb-2 pb-4 mt-2 md:mt-12 px-4 sm:px-4  "
      >
        <TXDButton
          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 "
          radius="full"
          size="sm"
          variant="secondary"
          border={false}
        >
          Cancel
        </TXDButton>
        <TXDButton
          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 "
          radius="full"
          size="sm"
          variant="secondary"
          border={false}
        >
          FUll ENROLLMENT
        </TXDButton>

        <TXDButton
          className="bg-white text-[#00AFD8] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#00AFD8] hover:bg-[#00AFD8] hover:text-white hover:ring-[#00AFD8] "
          radius="full"
          size="sm"
          variant="primary"
        >
          CONFIRM AND SEND EMAIL
        </TXDButton>
      </TXDFlex> */}
    </>
  );
};

export default ReviewStepper;
