import React from "react";
import { TXDFlex } from "@supporttxend/txd-components";
import { IPageWrapperProps } from "@/interfaces";

const PageWrapper: React.FC<IPageWrapperProps> = ({ children }) => {
  return (
    <TXDFlex variant="column" className="min-h-screen bg-[#FAFAFA]">
      {children}
    </TXDFlex>
  );
};

export default PageWrapper;
