import React from "react";
import PageWrapper from "@/components/PageWrapper";
import SignupNavbar from "@/components/SignupNavbar";
import SignUpFooter from "@/components/SignUpFooter";
import Otp from "@/components/Otp";

const ConfirmOtp: React.FC = () => {
  return (
    <PageWrapper>
      <SignupNavbar />
      <Otp />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default ConfirmOtp;
