import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { toast } from "react-hot-toast";
import { DOWNLOAD_DOCS } from "./api/userService";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";

const useDownloadDocs = () => {
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [error, setError] = useState(null);
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);
  const portfolioPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const downloadDocument = async (DocumentPermId: any) => {
    setLoading((prev) => ({ ...prev, [DocumentPermId]: true }));
    try {
      const response = await DOWNLOAD_DOCS(
        portfolioPermId,
        DocumentPermId,
        AccessCode
      );
      setLoading((prev) => ({ ...prev, [DocumentPermId]: false }));
      if (response instanceof Blob && response.size > 0) {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `download_${DocumentPermId}.pdf`);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100);
       toast.success(SUCCESS_MESSAGES.DOCS_DOWNLOADED);
      } else {
        toast.error(ERROR_MESSAGES.DOCS_NOT_FOUND);
      }
    } catch (error: any) {
      setLoading((prev) => ({ ...prev, [DocumentPermId]: false }));
      const errorMessage =
        error.response?.data?.message || ERROR_MESSAGES.DOCS_DOWN_FAILED;
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };
  return { downloadDocument, loading, error };
};

export default useDownloadDocs;
