import React from "react";
import { TXDBox, TXDFlex } from "@supporttxend/txd-components";

const NavRightSkeleton: React.FC = () => {
  const shimmerStyle = {
    backgroundSize: "450px 400px",
    animation: "shimmer 1.5s linear infinite",
  };

  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }
      100% {
        background-position: 450px 0;
      }
    }
  `;

  return (
    <TXDBox className="w-full bg-white p-4 rounded-lg shadow-lg animate-pulse overflow-hidden">
      <style>{shimmerKeyframes}</style>
      <TXDFlex variant="column">
        {/* First Row */}
        <TXDFlex className="flex justify-between">
          <TXDBox className="h-10 bg-gray-200 rounded-md overflow-hidden w-1/3">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
          <TXDBox className="h-10 bg-gray-200 rounded-md overflow-hidden w-1/3 ml-2">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
          <TXDBox className="h-10 bg-gray-200 rounded-md overflow-hidden w-1/3 ml-2">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
        </TXDFlex>
        {/* Second Row */}
        {/* <TXDFlex className="flex justify-between mt-4">
          <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-1/3">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
          <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-1/3 ml-2">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
          <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-1/3 ml-2">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
        </TXDFlex> */}
      </TXDFlex>
    </TXDBox>
  );
};

export default NavRightSkeleton;
