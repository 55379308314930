import { useLocation } from "react-router-dom";
import PageWrapper from "@/components/PageWrapper";
import SignUpFooter from "@/components/SignUpFooter";
import SignupNavbar from "@/components/SignupNavbar";
import React from "react";
import PSConfirmation from "../setupUser/PSConfirmation";
import PASetupUser from "../setupUser/PASetupUser";


const Step3: React.FC = () => {
  const location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/ps-confirmation":
      ComponentToRender = PSConfirmation;
      break;
    case "/pa-setup-user":
      ComponentToRender = PASetupUser;
      break;
    default:
      ComponentToRender = PSConfirmation;
      break;
  }
  return (
    <PageWrapper>
      <SignupNavbar />
      <ComponentToRender />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default Step3;
