import { useLocation } from "react-router-dom";

import PageWrapper from "@/components/PageWrapper";
import SignUpFooter from "@/components/SignUpFooter";
import SignupNavbar from "@/components/SignupNavbar";
import React from "react";
import TPACreateAccount from "../createAccount/TPACreateAccount";
import PSCreateAccount from "../createAccount/PSCreateAccount";
import PACreateAccount from "../createAccount/PACreateAccount";

const Step2: React.FC = () => {
  const location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/tpa-create-account":
      ComponentToRender = TPACreateAccount;
      break;
    case "/ps-create-account":
      ComponentToRender = PSCreateAccount;
      break;
    case "/pa-create-account":
      ComponentToRender = PACreateAccount;
      break;
    default:
      ComponentToRender = TPACreateAccount;
      break;
  }
  return (
    <PageWrapper>
      <SignupNavbar />
      <ComponentToRender />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default Step2;
