import React from "react";
import { TXDBox, TXDFlex } from "@supporttxend/txd-components";

const CardSkeleton: React.FC = () => {
  const shimmerStyle = {
    backgroundSize: "450px 400px",
    animation: "shimmer 1.5s linear infinite",
  };

  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -450px 0;
      }
      100% {
        background-position: 450px 0;
      }
    }
  `;

  return (
    <TXDBox className="w-full bg-white p-4 rounded-lg shadow-lg animate-pulse overflow-hidden   ">
      <style>{shimmerKeyframes}</style>
      <TXDFlex className="flex-col space-y-4">
        <TXDBox className="h-10 w-full bg-gray-200 rounded-md overflow-hidden">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 "
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-3/4">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-1/2">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-full">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-2/3">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-1/4">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
        <TXDBox className="h-4 bg-gray-200 rounded-md overflow-hidden w-full">
          <TXDBox
            className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
            style={shimmerStyle}
          ></TXDBox>
        </TXDBox>
      </TXDFlex>
    </TXDBox>
  );
};

export default CardSkeleton;
