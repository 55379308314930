import React, { useState } from "react";
import {
  TXDBox,
  TXDText,
  TXDFlex,
  TXDImage,
  TXDButton,
} from "@supporttxend/txd-components";
import companyLogo from "../assets/images/building.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import UpdateCompantInfoModal from "../layouts/modals/UpdateCompanyInfoModal";
import ShowCompanyInfoModal from "../layouts/modals/ShowCompanyInfoModal";
import ProfileSkeleton from "@/layouts/skeletons/ProfileSkeleton";
import { ITransformFunction, ICompanyTileProps } from "@/interfaces";
import { useParams } from "react-router";
import useFetchProfile from "@/services/userProfile";

const CompanyTile: React.FC<ICompanyTileProps> = ({
  data,
  componentPermissions,
}) => {
  const { permissionsList } = componentPermissions;
  const [open, setOpen] = useState(false);
  const [showCompany, setShowCompany] = useState(false);

  const { id } = useParams<{ id: string }>();

  const { loading, userProfile, refetch } =
    id === undefined ? data : useFetchProfile(id);

  const totalAssets = userProfile
    ? `$${userProfile.InternalPortfolio?.PortfolioAssetsLineage.toFixed(2)}`
    : "$0.00";

  const getValueOrDefault = (
    value: any,
    transformation: ITransformFunction = (val) => val
  ) => {
    return value ? transformation(value) : "--";
  };

  const legalName = getValueOrDefault(
    userProfile?.InternalPortfolio?.PortfolioLegalName
  );

  const Identifier = userProfile
    ? `${userProfile.Portfolio.PortfolioPermId}`
    : "--";

  const totalPlans = userProfile
    ? `${userProfile.InternalPortfolio.PlanCount}`
    : "0";

  const formatParticipantCount = (count: number) => {
    if (count === 0) {
      return "--";
    }
    return count < 10 ? `0${count}` : count;
  };

  const totalParticipants = useSelector(
    (state: RootState) => state.participants.totalParticipants
  );

  const formattedParticipants = formatParticipantCount(totalParticipants);

  const dataArray = [
    {
      key: "TOTAL ASSETS",
      value: totalAssets,
      icon: "increase",
    },
    {
      key: "TOTAL PLANS",
      value: totalPlans,
      icon: "decrease",
    },
    {
      key: "PARTICIPANTS",
      value: formattedParticipants,
      icon: "increase",
    },
  ];

  const onUpdate = () => {
    refetch().then(() => {
      setOpen(false);
    });
  };

  return (
    <>
      {permissionsList.read ? (
        <TXDFlex
          variant="column"
          className="shadow-lg mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8 bg-white"
        >
          {loading ? (
            <ProfileSkeleton />
          ) : (
            <>
              <TXDBox className="grid grid-cols-2 border-b-2 border-b-[#f9f9f9]">
                <TXDFlex
                  justifyContent="start"
                  alignItems="center"
                  className="border-l-4 py-4 px-4 border-l-[#4EC6E5]"
                >
                  <TXDText
                    align="left"
                    as="h1"
                    className="uppercase text-gray-500 whitespace-nowrap"
                    size="xl"
                    weight="normal"
                  >
                    {legalName}
                  </TXDText>
                </TXDFlex>
                {permissionsList.update ? (
                  <TXDFlex
                    justifyContent="end"
                    alignItems="center"
                    className="p-4"
                  >
                    <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
                      <FontAwesomeIcon
                        onClick={() => setOpen(true)}
                        icon={faPen}
                        className="hover:text-[#4EC6E5] hover:border-[#4EC6E5] border text-sm cursor-pointer text-gray-400 border-gray-400 p-1 rounded-full"
                      />
                    </TXDBox>
                  </TXDFlex>
                ) : (
                  <></>
                )}

                {open && (
                  <UpdateCompantInfoModal
                    modalOpen={open}
                    closeModal={() => setOpen(false)}
                    userProfile={{ ...userProfile }}
                    onUpdate={onUpdate}
                  />
                )}
              </TXDBox>

              <TXDBox className="grid grid-cols-12 px-10 sm:px-0">
                <TXDBox className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2">
                  <TXDFlex variant="row" className="py-4 px-2 sm:px-12 lg:px-6">
                    <TXDImage
                      display="inline-block"
                      radius="none"
                      src={companyLogo}
                      variant="cover"
                      className="h-20 w-auto"
                    />
                  </TXDFlex>
                </TXDBox>
                <TXDBox className="pb-3 border-b-2 gap-2 sm:gap-1 border-b-[#f9f9f9] lg:border-b-0 lg:pb-0 grid lg:grid-cols-4 xl:grid-cols-4 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 xl:col-span-8">
                  {dataArray.map((data: any, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TXDFlex className="px-2 sm:px-12 lg:px-0 lg:justify-center">
                          <TXDBox className="grid grid-cols-2 lg:grid-cols-1 w-full">
                            <TXDBox className="flex items-center lg:items-end">
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase  font-semibold lg:font-bold text-[12px] sm:text-[12px] md:text-[12px] lg:text-xs xl:text-xs"
                                size="xs"
                              >
                                {data.key}
                              </TXDText>
                            </TXDBox>
                            <TXDBox className="flex item-center lg:items-start">
                              <TXDText
                                as="span"
                                className="text-gray-500 text-[14px] sm:text-[16px] md:text-lg lg:text-xl xl:text-2xl"
                                weight="normal"
                              >
                                {data.value}
                              </TXDText>
                            </TXDBox>
                          </TXDBox>
                        </TXDFlex>
                      </React.Fragment>
                    );
                  })}
                </TXDBox>
                <TXDBox className="gap-2 grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 px-4 py-4 xl:py-0 lg:py-0 col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-2 xl:col-span-2">
                  <TXDFlex
                    variant="row"
                    alignItems="end"
                    className="justify-start lg:justify-center"
                  >
                    <TXDText
                      as="span"
                      className="text-gray-900 font-semibold lg:font-bold uppercase text-[10px] sm:text-[12px]"
                      weight="bold"
                    >
                      Identifier:
                    </TXDText>
                    <TXDText
                      as="span"
                      className="text-gray-500  font-semibold lg:font-bold uppercase text-[10px] sm:text-[12px]"
                      weight="bold"
                    >
                      {Identifier}
                    </TXDText>
                  </TXDFlex>
                  <TXDFlex
                    variant="row"
                    alignItems="start"
                    className="justify-start lg:justify-center "
                  >
                    <TXDButton
                      className="bg-[#FFFFFF] border font-bold uppercase text-[10px] sm:text-[11px] py-1 px-1 text-gray-400 shadow-sm rounded ring-gray-200 hover:bg-gray-500 hover:text-white hover:ring-gray-500"
                      id="addcompanyinfo"
                      onClick={() => setShowCompany(true)}
                    >
                      Show Company Info
                    </TXDButton>
                  </TXDFlex>
                  {showCompany && (
                    <ShowCompanyInfoModal
                      modalOpen={showCompany}
                      closeModal={() => setShowCompany(false)}
                      identifier={userProfile.Portfolio.PortfolioPermId}
                    />
                  )}
                </TXDBox>
              </TXDBox>
            </>
          )}
        </TXDFlex>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompanyTile;
