import React from "react";
import PageWrapper from "@/components/PageWrapper";
import SignupNavbar from "@/components/SignupNavbar";
import SignUpFooter from "@/components/SignUpFooter";
import ForgotPassword from "@/components/ForgotPassword";

const ForgotPasswordPage: React.FC = () => {
  return (
    <PageWrapper>
      <SignupNavbar />
      <ForgotPassword />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default ForgotPasswordPage;
