import React, { useState } from "react";
import { TXDBox, TXDTable } from "@supporttxend/txd-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import useFetchTransaction from "@/services/useFetchTransacation";
import ShowTransacationModal from "../layouts/modals/ShowTransacationModal";
import { findTransactionStatus } from "@/utils/componentUtils";
const TransacationTable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [currentTransactionPermId, setCurrentTransactionPermId] = useState<
    number | null
  >(null);

  const { id } = useParams<{ id: any }>();
  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;
  const handleRowClick = (
    portfolioPermId: number,
    transactionPermId: number
  ) => {
    if (portfolioPermId === null || transactionPermId === null) return;
    setCurrentPortfolioPermId(portfolioPermId);
    setCurrentTransactionPermId(transactionPermId);
    setOpen(true);
  };

  const { userTransaction, loading } = useFetchTransaction(effectiveId);

  const tableData =
    userTransaction?.TransactionData?.map((item: any) => ({
      id: item.TransactionPermId,
      transaction: (
        <div
          className="cursor-pointer"
          onClick={() => {
            if (effectiveId !== null && item.TransactionPermId !== null) {
              handleRowClick(effectiveId, item.TransactionPermId);
            }
          }}
        >
          {item.Desc}
        </div>
      ),

      tradeDate: new Date(item.TradeDate).toLocaleDateString(),
      amount: (
        <span className={`text-${item.Amount >= 0 ? "green" : "red"}-300`}>
          ${item.Amount.toLocaleString()}
        </span>
      ),
      status: (
        // <span
        //   className={`text-${
        //     findTransactionStatus(item.Status).badge === "success"
        //       ? "green"
        //       : "red"
        //   }-300 text-red-700`}
        // >
        //   <FontAwesomeIcon
        //     icon={
        //       findTransactionStatus(item.Status).badge === "success"
        //         ? faCheckCircle
        //         : faCircleExclamation
        //     }
        //     className="mr-2"
        //   />
        //   {findTransactionStatus(item.Status).name}
        // </span>
        <span
          style={{
            color:
              findTransactionStatus(item.Status).badge === "success"
                ? "rgb(74 222 128)"
                : findTransactionStatus(item.Status).badge === "warning"
                ? "rgb(250 204 21)"
                : "color: rgb(248 113 113)",
          }}
        >
          <FontAwesomeIcon
            icon={
              findTransactionStatus(item.Status).badge === "success"
                ? faCheckCircle
                : findTransactionStatus(item.Status).badge === "warning"
                ? faExclamationTriangle
                : faCircleExclamation
            }
            className="mr-2"
            style={{
              color:
                findTransactionStatus(item.Status).badge === "success"
                  ? "rgb(74 222 128)"
                  : findTransactionStatus(item.Status).badge === "warning"
                  ? "rgb(250 204 21)"
                  : "color: rgb(248 113 113)",
            }}
          />
          {findTransactionStatus(item.Status).name}
        </span>
      ),
      onClick: handleRowClick,
    })) || [];
  const tableHead = ["id", "transaction", "trade date", "amount", "status"];

  return (
    <TXDBox className="shadow-lg bg-[#ffffff] mb-20 mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
      <TXDBox className="overflow-x-auto">
        {loading ? (
          <table className="min-w-full divide-y divide-gray-300">
            <TableSkeleton />
          </table>
        ) : (
          <TXDTable
            tHead={tableHead}
            tRow={tableData}
            entries={true}
            pagination={true}
            filteration={true}
            title="LATEST TRANSACTIONS"
          />
        )}
        {/* <TXDFlex className=" py-4 bg-white" justifyContent="center">
          <TXDButton
            className="shadow-sm uppercase text-medium"
            radius="none"
            size="md"
            variant="secondary"
            border={false}
          >
            View all transactions (512)
          </TXDButton>
        </TXDFlex> */}
        {open &&
          currentPortfolioPermId !== null &&
          currentTransactionPermId !== null && (
            <ShowTransacationModal
              modalOpen={open}
              closeModal={() => setOpen(false)}
              portfolioPermId={currentPortfolioPermId}
              transactionPermId={currentTransactionPermId}
            />
          )}
      </TXDBox>
    </TXDBox>
  );
};

export default TransacationTable;
