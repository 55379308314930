import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  TXDText,
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDSelect,
} from "@supporttxend/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import "../../styles/index.css";
import {
  PORTFOLIO_ADDRESS_ADD_RQ,
  PORTFOLIO_CONTACT_MOD_RQ,
} from "@/services/api/userService";
import { IEditSponsorModalProps } from "@/interfaces";
import SaveSpinner from "../skeletons/SaveSpinner";
import { BUSINESS_TYPES, STATE_PROVINCE } from "@/constants/enumConstants";
import { validateAge, loadGoogleMapsScript } from "@/utils/componentUtils";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "../skeletons/toastUtils";

const EditAdvisorModal: React.FC<IEditSponsorModalProps> = ({
  modalOpen,
  closeModal,
  onUpdate,
  userProfile,
  portfolioPermId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const accessCode = useSelector(
    (state: RootState) => state.auth.AccessCode as string
  );

  useEffect(() => {
    if (modalOpen) {
      const initializeGoogle = async () => {
        try {
          await loadGoogleMapsScript("address", setAddressValue);
        } catch (error) {
          // console.log(error);
        }
      };
      initializeGoogle();
    }
  }, [modalOpen]);
  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
  });

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();

    // Extract values from form
    const firstName = e.target[0].value;
    const lastName = e.target[1].value;
    const legalName = e.target[2].value;
    const taxId = e.target[3].value;
    const dob = e.target[4].value;
    const businessClass = e.target[5].value;
    const streetAddress = e.target[6].value;
    const streetAddress2 = e.target[7].value;
    const stateProvincs = e.target[8].value;
    const city = e.target[9].value;
    const zipCode = e.target[10].value;

    const phoneNo = e.target[11].value;
    const email = e.target[12].value;

    console.table({
      legalName,
      taxId,
      dob,
      businessClass,
      streetAddress,
      streetAddress2,
      stateProvincs,
      city,
      zipCode,
      firstName,
      lastName,
      phoneNo,
      email,
    });

    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.INPUTS_ERROR);
      setLoading(false);
      return;
    } else {
      toast
        .promise(
          Promise.all([
            await PORTFOLIO_ADDRESS_ADD_RQ(
              {
                PortfolioPermId: sponsor.PortfolioPermId,
                Addr1: streetAddress,
                Addr2: streetAddress2,
                Addr3: "",
                Addr4: "",
                City: city,
                StateProv: stateProvincs,
                PostalCode: zipCode ? parseInt(zipCode, 10) : null,
                Country: userProfile?.PortfolioCountry || "",
                AddressTypePermId: 100,
                IsPrimary: true,
              },
              accessCode
            ),
            await PORTFOLIO_CONTACT_MOD_RQ(
              {
                PortfolioPermId: sponsor.PortfolioPermId,
                EmailAddr: email,
                EmailAddr2: email || "",
                Phone: phoneNo,
                PhoneType: phoneNo.length,
                EmpEmailAddr: "",
              },
              accessCode
            ),
          ]),
          {
            loading: LOADING_MESSAGES.PROFILE_LOADING,
            success: <b>{SUCCESS_MESSAGES.PROFILE_ADDED}</b>,
            error: <b>{ERROR_MESSAGES.PROFILE_FAILED}</b>,
          }
        )
        .then(() => {
          onUpdate();
          closeModal();
          setLoading(false);
        })
        .catch((error: any) => {
          toast.error(ERROR_MESSAGES.PROFILE_FAILED || error?.message);
          setLoading(false);
        });
    }
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  let sponsor: any = {};
  if (userProfile) {
    const sponsors = Object.values(userProfile).filter(
      (sponsor: any) => sponsor && sponsor.PortfolioPermId === portfolioPermId
    );
    if (sponsors.length > 0) {
      sponsor = sponsors[0];
    } else {
      console.error(ERROR_MESSAGES.SPONSOR_FAILED);
    }
  }
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validateAge(event.target.value);
    setDateError(errorMessage);
    updateErrorState("dob", !!errorMessage);
  };
  // console.log(sponsor, "mysponsors");
  // console.log(userProfile, "myuserprofile");
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto flex items-center justify-center "
          onClose={() => {
            //Do nothing
          }}
          data-testid="editAdminstratorModal"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <TXDBox className="inline-block  w-full max-w-2xl my-8 mx-12  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <TXDFlex
                justifyContent="center"
                className=" "
                alignItems="center"
              >
                <TXDBox className="modalOverlay w-full  ">
                  <TXDBox>
                    <TXDFlex
                      alignItems="center"
                      className="p-4 flex justify-between border-b"
                    >
                      <TXDText
                        align="left"
                        as="h1"
                        className="uppercase  text-gray-500 sm:text-xl text-md whitespace-nowrap  "
                        size="xl"
                        weight="normal"
                      >
                        Edit Administrator
                      </TXDText>
                      <TXDBox className="tooltip-wrapper" data-tooltip="Close">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none `}
                          onClick={closeModal}
                        />
                      </TXDBox>
                    </TXDFlex>
                  </TXDBox>
                  <TXDFlex
                    className="overflow-y-auto  p-4 participant-table"
                    style={{ maxHeight: "calc(100vh - 160px)" }}
                  >
                    <form
                      className="w-full"
                      id="profileForm"
                      onSubmit={handleSubmit}
                    >
                      <TXDInput
                        id="firstName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="First Name"
                        placeholder="Enter first name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^[A-Za-z][A-Za-z0-9 ]*$/}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input1", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioFirstName || ""}
                      />
                      <TXDInput
                        id="lastName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="Last Name"
                        placeholder="Enter last name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^[A-Za-z][A-Za-z0-9 ]*$/}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input2", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioLastName || ""}
                      />
                      <TXDInput
                        id="legalName"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Legal Name"
                        placeholder="Enter legal name"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^[A-Za-z][A-Za-z0-9 ]*$/}
                        initialValue={sponsor?.PortfolioLegalName}
                        required
                        filled={true}
                        disabled
                      />
                      <TXDInput
                        id="taxId"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Tax ID"
                        placeholder="Enter tax ID"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^[A-Za-z0-9]{9}$/}
                        required
                        errorExist={(error) =>
                          updateErrorState("input4", error ? true : false)
                        }
                        initialValue={sponsor?.PortfolioTaxIdentifier || ""}
                        disabled
                      />
                      <TXDInput
                        id="dob"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Date of Birth"
                        placeholder="YYYY-MM-DD"
                        sizing="xs"
                        type="date"
                        variant="styled"
                        required
                        errorExist={(error) =>
                          updateErrorState(
                            "input5",
                            error || dateError ? true : false
                          )
                        }
                        initialValue={
                          sponsor?.PortfolioBirthDate.slice(0, 10) || ""
                        }
                        regex={
                          /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
                        }
                        max={new Date().toISOString().split("T")[0]}
                        onInput={handleDateChange}
                      />
                      {dateError && (
                        <TXDBox className="text-red-500 font-normal text-sm leading-[1.25rem]">
                          {dateError}
                        </TXDBox>
                      )}

                      <TXDSelect
                        className="external-downimg pl-0"
                        id="selectCountry"
                        isObjectArray
                        label
                        defaultValue={""}
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs"
                        labelText="Business Class"
                        sizing="xs"
                        variant="styled"
                        required
                        optionsArray={[
                          {
                            label: "-- Please select bussinessclass --",
                            value: "",
                            disabled: true,
                          },
                          ...BUSINESS_TYPES.map((business) => ({
                            label: business.label,
                            value: business.value,
                          })),
                        ]}
                      />
                      <TXDInput
                        id="address"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="Street Address"
                        placeholder="Enter street address"
                        sizing="xs"
                        variant="styled"
                        required
                        value={addressValue}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                          setAddressValue(e.currentTarget.value);
                        }}
                        errorExist={(error: any) =>
                          updateErrorState("input7", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioAddress1 || ""}
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                      >
                        (cannot be a P.O. Box)
                      </TXDText>
                      <TXDInput
                        id="streetAddress2"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="Street Address 2"
                        placeholder="Enter street address 2 (Optional)"
                        sizing="xs"
                        variant="styled"
                        className="mb-1 pl-0"
                        errorExist={(error: any) =>
                          updateErrorState("input8", error ? true : false)
                        }
                        initialValue={sponsor?.PortfolioAddress1 || ""}
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                      >
                        (address line 2)
                      </TXDText>
                      <TXDSelect
                        className="external-downimg pl-0"
                        id="selectCountry"
                        isObjectArray
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="State or Province"
                        optionsArray={STATE_PROVINCE["US"].map((state) => ({
                          label: state.name || state.label,
                          value: state.state_code || state.value,
                          disabled: state.disabled,
                        }))}
                        sizing="xs"
                        variant="styled"
                        required
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                      >
                        (no abbreviations)
                      </TXDText>
                      <TXDInput
                        defaultValue=""
                        id="selectCity"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer text-xs "
                        variant="styled"
                        className="pl-0"
                        labelText={"City"}
                        placeholder="Enter city name"
                        type="text"
                        sizing="xs"
                        regex={
                          /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+(?:[\s'-][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)*$/
                        }
                        errorExist={(error: any) =>
                          updateErrorState("input10", error ? true : false)
                        }
                        required
                      />
                      <TXDInput
                        id="zipCode"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="Zip or Postal Code"
                        placeholder="Enter zip or postal code"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^\d{5}(-\d{4})?$/}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input11", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioPostalCode || ""}
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                      >
                        (or Postal Code)
                      </TXDText>

                      <TXDInput
                        id="phoneNumber"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs "
                        labelText="Phone Number"
                        placeholder="Enter phone number"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/^\(\d{3}\) \d{3}-\d{4}$/}
                        required
                        errorExist={(error: any) =>
                          updateErrorState("input12", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioPhone || ""}
                      />
                      <TXDText
                        as="p"
                        size="xs"
                        weight="normal"
                        className="text-gray-400 whitespace-nowrap  mt-1 mb-1"
                      >
                        (###) ###-####
                      </TXDText>
                      <TXDInput
                        id="email"
                        className="pl-0"
                        label
                        labelClass="font-medium text-gray-400 cursor-pointer uppercase text-xs  pb-44"
                        labelText="Email Address"
                        placeholder="Enter email address"
                        sizing="xs"
                        type="text"
                        variant="styled"
                        regex={/\S+@\S+\.\S+/}
                        required
                        errorExist={(error) =>
                          updateErrorState("input13", error ? true : false)
                        }
                        initialValue={sponsor.PortfolioEmailAddress1 || ""}
                      />
                      <TXDFlex
                        justifyContent="end"
                        className="gap-4 px-4 py-4 bg-white fixed bottom-0 left-0 right-0 flex-col-reverse sm:flex-row z-30"
                      >
                        <TXDButton
                          className="bg-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-secondary-400 hover:text-white hover:ring-secondary-400 sm:w-[115px]"
                          radius="full"
                          variant="secondary"
                          border={false}
                          onClick={closeModal}
                        >
                          Cancel
                        </TXDButton>
                        <TXDButton
                          className="bg-[#4EC6E5] text-white uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] sm:w-[120px]"
                          radius="full"
                          variant="primary"
                          type="submit"
                        >
                          {loading ? <SaveSpinner /> : "SAVE"}
                        </TXDButton>
                      </TXDFlex>
                    </form>
                  </TXDFlex>
                </TXDBox>
              </TXDFlex>
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default EditAdvisorModal;
