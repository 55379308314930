import React, { useEffect, useState } from "react";
import {
  TXDBox,
  TXDTable,
  TXDFlex,
  TXDButton,
} from "@supporttxend/txd-components";
// import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import CardSkeleton from "@/layouts/skeletons/CardSkeleton";
import AddPlanAdministratorModal from "@/layouts/modals/AddPlanAdministratorModal";
import ShowCompanyInfoModal from "@/layouts/modals/ShowCompanyInfoModal";
import { Administrator } from "@/types";
import EditAdministratorModal from "@/layouts/modals/EditAdministratorModal";
import { RootState } from "@/redux/store";
import { useSelector } from "react-redux";
import { IAdministratorTableList } from "@/interfaces";
import { getHumanReadableStatus } from "@/utils/componentUtils";
import { RO_CONSTANTS } from "@/constants/RolloverConstants";
import useFetchUserList from "@/services/useFetchUserList";
import { useParams } from "react-router";

const AdministratorTableList: React.FC<IAdministratorTableList> = (props) => {
  const { id } = useParams<{ id: string }>();
  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;
  const { permissionsList } = props.componentPermissions;
  const [localAdministrator, setlocalAdministrator] = useState<Administrator[]>(
    []
  );
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null);
  const [showCompany, setShowCompany] = useState(false);
  const { users, loading, refetch } = useFetchUserList(
    RO_CONSTANTS.PORTFOLIO_TYPE_ID.PLAN_ADMINISTRATOR,
    effectiveId
  );
  useFetchUserList;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setlocalAdministrator(users || []);
  }, [users]);
  useEffect(() => {
    //
  }, [localAdministrator]);

  const underlineFirstFourLetters = (name: string) => {
    if (!name) return "Unknown";
    const firstFour = name.slice(0, 4);
    const rest = name.slice(4);
    return (
      <span>
        <span className="border-b-2 border-[#4EC6E5]">{firstFour}</span>
        {rest}
      </span>
    );
  };

  const formatAdministratorData = (data: any[]) => {
    if (!Array.isArray(data)) return [];
    return data.map((administrator) => ({
      id: administrator.PortfolioPermId || "N/A",
      administrator: (
        <a
          href={`/administrator/${administrator.PortfolioPermId}`}
          className="font-bold"
          rel="noreferrer"
        >
          {underlineFirstFourLetters(administrator.PortfolioFirstName)}{" "}
          {administrator.PortfolioLastName || "N/A"}
        </a>
      ),

      assets: administrator?.PortfolioAssetsLineage
        ? `$ ${administrator?.PortfolioAssetsLineage.toLocaleString()}`
        : "$ 0",
      status: getHumanReadableStatus(administrator.PortfolioStatus),
      actions: (
        <TXDFlex className="gap-2" alignItems="center">
          {permissionsList.read ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="View">
              <FontAwesomeIcon
                icon={faEye}
                className="cursor-pointer text-[#00AFD8]"
                onClick={() => {
                  setSelectedAdminId(administrator.PortfolioPermId);
                  setShowCompany(true);
                }}
              />
            </TXDBox>
          ) : (
            <></>
          )}
          {permissionsList.update ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
              <FontAwesomeIcon
                icon={faEdit}
                className="cursor-pointer text-[#38D6B5]"
                onClick={() => handleEditClick(administrator.PortfolioPermId)}
              />
            </TXDBox>
          ) : (
            <></>
          )}
        </TXDFlex>
      ),
    }));
  };

  const handleAddAdministrator = (newAdministrator: Administrator) => {
    setlocalAdministrator((prevAdministrators) => {
      const updatedAdministrators = [...prevAdministrators, newAdministrator];
      return updatedAdministrators.filter(
        (admin, index, self) =>
          index === self.findIndex((t) => t.email === admin.email)
      );
    });
  };

  const handleEditClick = (portfolioPermId: number) => {
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
  };

  const handleAddSponsor = (newSponsor: any) => {
    setlocalAdministrator((prevSponsors) => [...prevSponsors, newSponsor]);
  };

  const onUpdate = () => {
    refetch().then(() => {
      // console.log("Data refetched after update");
      setEditModal(false);
    });
  };

  const tableHead = ["id", "Name", "Assets", "Status", "Actions"];

  const tableData = formatAdministratorData(users);

  return (
    <>
      {permissionsList.read ? (
        <TXDBox className="shadow-lg bg-[#ffffff] pb-4">
          <TXDBox className="overflow-x-auto">
            {loading ? (
              <table className="min-w-full divide-y divide-gray-300">
                <CardSkeleton />
              </table>
            ) : (
              <TXDTable
                filteration={true}
                pagination={true}
                entries={true}
                tHead={tableHead}
                tRow={tableData}
                title="Plan Administrator"
              />
            )}
            {permissionsList.write ? (
              <TXDFlex
                className="w-full px-4 py-4 gap-4 bg-white"
                justifyContent="end"
              >
                {/* <TXDBox className="tooltip-wrapper" data-tooltip="Add"> */}
                <TXDButton
                  className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
                  radius="none"
                  variant="primary"
                  border={false}
                  onClick={() => setOpen(true)}
                >
                  <FontAwesomeIcon className="text-xs" icon={faPlus} /> Add
                  Administrator
                </TXDButton>
                {/* </TXDBox> */}
              </TXDFlex>
            ) : (
              <></>
            )}

            {open && (
              <AddPlanAdministratorModal
                modalOpen={open}
                closeModal={() => setOpen(false)}
                onAddAdminstrator={handleAddAdministrator}
              />
            )}
            {showCompany && selectedAdminId !== null && (
              <ShowCompanyInfoModal
                modalOpen={showCompany}
                closeModal={() => setShowCompany(false)}
                identifier={selectedAdminId}
              />
            )}
            {editModal && currentPortfolioPermId !== null && (
              <EditAdministratorModal
                modalOpen={editModal}
                closeModal={() => setEditModal(false)}
                onAddSponsor={handleAddSponsor}
                userProfile={{ ...users }}
                onUpdate={onUpdate}
                portfolioPermId={currentPortfolioPermId}
              />
            )}
          </TXDBox>
        </TXDBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdministratorTableList;
