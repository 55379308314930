import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDText,
} from "@supporttxend/txd-components";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux/store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { VALIDATE_TFA_CODE } from "@/services/api/userService";
import { RootState } from "@/redux/store";
import { useCookies } from "react-cookie";
import SaveSpinner from "@/layouts/skeletons/SaveSpinner";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";
import { THIRD_PARTY_CALL } from "@/constants/3rdPartyCallContant";
import { loginSuccess } from "@/redux/slices/authSlice";
const Otp: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const wholeObject = useSelector((state: RootState) => state.auth);
  const referenceValue = useSelector(
    (state: RootState) => state.auth.ReferenceValue
  );
  // console.log(wholeObject);
  const userName = useSelector((state: RootState) => state.auth.username);
  const [loading, setLoading] = useState(false);
  const [otp, setOtpState] = useState(new Array(6).fill(""));
  const navigate = useNavigate();
  const [userIPAddress, setUserIPAddress] = useState("");
  const [cookies, setCookie] = useCookies(["IdentityToken", "Username"]);
  useEffect(() => {
    if (cookies.IdentityToken && cookies.Username) {
      console.log("User is already logged in");
    }
  }, [cookies]);

  useEffect(() => {
    fetch(THIRD_PARTY_CALL.GET_IP_ADDRESS)
      .then((response) => response.json())
      .then((data) => setUserIPAddress(data.ip))
      .catch((error) => console.error("Error fetching IP:", error));
  }, []);
  const handleChange = (element: HTMLInputElement, index: number) => {
    if (isNaN(Number(element.value))) return;
    setOtpState([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      (element.nextSibling as HTMLInputElement).focus();
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const authCode = otp.join("");
    toast
      .promise(
        VALIDATE_TFA_CODE(authCode, referenceValue, true, userIPAddress),

        {
          loading: LOADING_MESSAGES.OTP_LOADING,
          success: <b>{SUCCESS_MESSAGES.OTP_VERFIED}</b>,
          error: <b>{ERROR_MESSAGES.OTP_VERFIED_FAILED}</b>,
        }
      )
      .then((response: any) => {
        const { IdentityToken } = response;
        // console.log("This is response => ", response);

        setCookie("IdentityToken", IdentityToken, {
          path: "/",
          maxAge: 30 * 24 * 60 * 60,
        });
        setCookie("Username", userName, {
          path: "/",
          maxAge: 30 * 24 * 60 * 60,
        });
        dispatch(
          loginSuccess({
            AccessCode: response?.AccessCode,
            ReferenceValue: response?.ReferenceValue,
            expirationTime: response?.expirationTime || null,
            policy: response?.Policy,
            PortfolioPermId: response?.PortfolioPermId,
            username: userName,
          })
        );
        setLoading(false);
        navigate("/");
      })
      .catch((error: any) => {
        toast.error(ERROR_MESSAGES.OTP_VERFIED_FAILED || error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    const form = document.getElementById("otp-form") as HTMLFormElement;
    if (!form) return;

    const inputs = Array.from(
      form.querySelectorAll("input[type=text]")
    ) as HTMLInputElement[];
    const submit = form.querySelector(
      "button[type=submit]"
    ) as HTMLButtonElement;

    const handleKeyDown = (e: KeyboardEvent) => {
      const target = e.target as HTMLInputElement;
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputs.indexOf(target);
        if (index > 0) {
          inputs[index - 1].value = "";
          inputs[index - 1].focus();
        }
      }
    };

    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const index = inputs.indexOf(target);
      if (target.value) {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        } else {
          submit?.focus();
        }
      }
    };

    const handleFocus = (e: FocusEvent) => {
      const target = e.target as HTMLInputElement;
      target.select();
    };

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      const text = e.clipboardData?.getData("text") || "";
      if (!new RegExp(`^[0-9]{${inputs.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputs.forEach((input, index) => (input.value = digits[index]));
      submit?.focus();
    };

    inputs.forEach((input) => {
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("input", handleInput);
        input.removeEventListener("keydown", handleKeyDown);
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("paste", handlePaste);
      });
    };
  }, []);

  return (
    <TXDFlex
      variant="column"
      justifyContent="center"
      className="flex min-h-full flex-1 px-6 lg:px-8 bg-[#FAFAFA]"
    >
      <TXDBox className="sm:mx-auto sm:w-full sm:max-w-md bg-white rounded-lg shadow-lg p-10 mt-12">
        <TXDText
          as="span"
          align="center"
          size="xl"
          weight="bold"
          className="text-center flex justify-center py-4  text-gray-900"
        >
          Confirm OTP
        </TXDText>
        <TXDText
          as="span"
          align="center"
          size="sm"
          weight="normal"
          className="text-center flex justify-center py-4  text-gray-900"
        >
          Enter the 6-digit verification code that was sent to your mobile phone
          or email.
        </TXDText>
        <form className="space-y-6" onSubmit={handleSubmit} id="otp-form">
          <TXDFlex className="flex items-center justify-center gap-3">
            {otp.map((data, index) => (
              <TXDInput
                key={index}
                maxLength={1}
                className="w-14 h-14 text-center text-2xl font-medium text-slate-900 bg-slate-100 border border-transparent  appearance-none rounded p-4 outline-none focus:bg-white focus:border-[#4EC6E5]"
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                required
                variant={"styled"}
              />
            ))}
          </TXDFlex>

          <TXDBox className="text-center">
            <TXDButton
              type="submit"
              className="bg-[#4EC6E5]  justify-center  text-white uppercase py-2 mb-4 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-[#4EC6E5] hover:bg-[#40a0c0] hover:text-white hover:ring-[#40a0c0] "
              radius="full"
              variant="primary"
            >
              {loading ? <SaveSpinner /> : " Confirm OTP"}
            </TXDButton>
          </TXDBox>
        </form>
      </TXDBox>
    </TXDFlex>
  );
};

export default Otp;
