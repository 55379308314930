import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCircleExclamation,
  faFilePdf,
  faPrint,
  faCaretRight,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";
import { TXDText, TXDBox, TXDFlex } from "@supporttxend/txd-components";
import { Dialog, Transition } from "@headlessui/react";
import useFetchTransactionDetails from "@/services/useFetchTransacationDetails";
import TransactionSkeleton from "@/layouts/skeletons/TransactionSkeleton";
import { IShowTransactionModalProps } from "@/interfaces";

const ShowTransacationModal: React.FC<IShowTransactionModalProps> = ({
  modalOpen,
  closeModal,
  portfolioPermId,
  transactionPermId,
}: any) => {
  const { transactionDetail, loading } = useFetchTransactionDetails(
    portfolioPermId,
    transactionPermId
  );

  const transactionData = [
    {
      label: "Transaction Type",
      value: transactionDetail?.TransactionData?.Desc,
    },
    { label: "Initiator", value: "Jane Thompson" },
    { label: "Request ID", value: "124422" },
    {
      label: "Amount",
      value: `+ $${transactionDetail?.TransactionData?.Amount}`,
    },
    {
      label: "Status",
      value:
        transactionDetail?.TransactionData?.Status === 183
          ? "Received"
          : "Pending",
    },
    {
      label: "Identifier",
      value: transactionDetail?.TransactionData?.TransactionPermId,
    },
  ];

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const doc = new jsPDF();

    doc.text("Transaction Details", 10, 10);
    let yPos = 20;
    transactionData.forEach((item) => {
      doc.text(`${item.label}: ${item.value}`, 10, yPos);
      yPos += 10;
    });

    doc.save("TransactionDetails.pdf");
  };
  const formatDate = (dateString: any) => {
    if (!dateString || isNaN(Date.parse(dateString))) return "N/A";
    return new Date(dateString).toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0  flex items-center justify-center z-50"
          onClose={() => {
            //Do nothing
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
          >
            <TXDBox className="inline-block  w-full max-w-7xl my-8 mx-12 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              {loading ? (
                <TransactionSkeleton />
              ) : (
                <TXDFlex
                  justifyContent="center"
                  className="participant-table "
                  alignItems="center"
                  style={{ maxHeight: "90vh", overflowY: "auto" }}
                >
                  <TXDBox className="modalOverlay w-full  overflow-y-scroll h-screen mt-48 ">
                    <TXDBox className="fixed top-0 left-0 right-0 z-30 bg-white">
                      <TXDFlex
                        alignItems="center"
                        className=" flex justify-between border border-gray-100 p-4"
                      >
                        <TXDBox className="w-full flex  items-center gap-6 ">
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500 sm:text-xl text-md"
                            size="xl"
                            weight="normal"
                          >
                            TRANSACTIONS
                          </TXDText>

                          {/* <FontAwesomeIcon
                            className="text-gray- $13.000 sm:h-6  h-4"
                            icon={faAngleRight}
                          /> */}

                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase text-gray-500 sm:text-xl text-md"
                            size="xl"
                            weight="normal"
                          >
                            {
                              transactionDetail?.TransactionData
                                ?.TransactionPermId
                            }
                          </TXDText>
                        </TXDBox>
                        <TXDBox
                          className="tooltip-wrapper"
                          data-tooltip="Close"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className={`w-4 h-4 cursor-pointer text-gray-500 hover:text-gray-700 focus:outline-none`}
                            onClick={closeModal}
                          />
                        </TXDBox>
                      </TXDFlex>
                    </TXDBox>
                    <TXDBox className="shadow-sm ">
                      <TXDFlex className=" bg-white " variant="column">
                        <TXDBox className="grid grid-cols-12  p-4 sm:p-8  gap-4 md:gap-8">
                          <TXDFlex
                            variant="column"
                            className="gap-5 col-span-12 md:col-span-5 w-full  "
                          >
                            <TXDBox className="flex sm:flex-col flex-row">
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase sm:pt-0 pt-1"
                                size="sm"
                                weight="bold"
                              >
                                TRANSACTION
                              </TXDText>
                              <TXDText
                                as="span"
                                className="text-gray-500 sm:text-2xl text-md"
                                size="2xl"
                                weight="normal"
                              >
                                {transactionDetail?.TransactionData?.Desc}
                              </TXDText>
                            </TXDBox>
                            <TXDFlex className=" gap-28 sm:gap-16">
                              <TXDText
                                as="span"
                                className="text-gray-500 uppercase"
                                size="sm"
                                weight="bold"
                              >
                                INITIATOR
                              </TXDText>

                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase text-xs font-normal"
                                size="sm"
                                weight="normal"
                              >
                                <span className="border-b-2 border-green-500 text-sm font-normal">
                                  {transactionDetail?.TransactionData
                                    ?.FirstName || "N/A"}
                                </span>
                                &nbsp;{" "}
                                {transactionDetail?.TransactionData?.LastName}
                              </TXDText>
                            </TXDFlex>

                            <TXDFlex className=" gap-24 sm:gap-16 ">
                              <TXDText
                                as="span"
                                className="text-gray-500 uppercase "
                                size="sm"
                                weight="bold"
                              >
                                REQUEST ID
                              </TXDText>

                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase text-sm font-normal "
                                size="sm"
                                weight="normal"
                              >
                                <span className="border-b-2 border-green-500 text-sm font-normal">
                                  1244
                                </span>
                                22
                              </TXDText>
                            </TXDFlex>
                          </TXDFlex>

                          <TXDFlex className=" col-span-12 sm:col-span-4 md:col-span-3 sm:flex-col  flex-row  gap-28 sm:gap-2  ">
                            <TXDBox>
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase"
                                size="sm"
                                weight="bold"
                              >
                                Amount
                              </TXDText>
                            </TXDBox>
                            <TXDBox className="flex flex-col">
                              <TXDText
                                as="span"
                                className="text-gray-500 sm:text-xl text-md"
                                size="xl"
                                weight="normal"
                              >
                                + ${" "}
                                {transactionDetail?.TransactionData?.Amount.toLocaleString()}
                              </TXDText>

                              <TXDText
                                as="span"
                                className="text-gray-900 sm:mt-2"
                                size="sm"
                                weight="normal"
                              >
                                Units:{" "}
                                {transactionDetail?.TransactionData?.Units.toLocaleString()}
                              </TXDText>
                            </TXDBox>
                          </TXDFlex>
                          <TXDFlex className="sm:col-span-2  col-span-12">
                            <TXDBox className="flex-row sm:flex-col  gap-32 sm:gap-2 flex w-full">
                              <TXDBox className="">
                                <TXDText
                                  as="span"
                                  className="text-gray-900 uppercase"
                                  size="sm"
                                  weight="bold"
                                >
                                  STATUS
                                </TXDText>
                              </TXDBox>
                              <TXDBox className="flex flex-col">
                                <TXDText
                                  as="span"
                                  className="text-gray-500 sm:text-lg text-md"
                                  size="xl"
                                  weight="normal"
                                >
                                  {transactionDetail?.TransactionData
                                    ?.Status === 183
                                    ? "Received"
                                    : "Pending"}
                                </TXDText>
                                <TXDText
                                  as="span"
                                  className="text-gray-900 mt-4 sm:text-sm"
                                  size="sm"
                                  weight="normal"
                                >
                                  CANCEL TRANSACTION
                                </TXDText>
                              </TXDBox>
                            </TXDBox>
                          </TXDFlex>

                          <TXDFlex className="col-span-12 sm:col-span-4  md:col-span-2 ">
                            <TXDBox className="flex sm:flex-col flex-row   gap-10 sm:gap-5 w-full ">
                              <TXDText
                                as="span"
                                className="text-gray-900 uppercase"
                                size="sm"
                                weight="normal"
                              >
                                IDENTIFIER:{" "}
                                {
                                  transactionDetail?.TransactionData
                                    ?.TransactionPermId
                                }
                              </TXDText>

                              <TXDFlex className="gap-7 mt-0 sm:mt-4 ml-2">
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  onClick={handleDownload}
                                  className="h-4 w-4 text-gray-400 cursor-pointer"
                                />
                                <FontAwesomeIcon
                                  icon={faPrint}
                                  onClick={handlePrint}
                                  className="h-4 w-4 text-gray-400 cursor-pointer"
                                />
                              </TXDFlex>
                            </TXDBox>
                          </TXDFlex>
                        </TXDBox>

                        <TXDBox className="grid grid-cols-2  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-6 border-t border-gray-200 p-4 sm:p-8  pl-4 gap-4 md:gap-8">
                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              ADD DATE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              REQUEST DATE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              TRADE DATE
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              EFFECTIVE DATE
                            </TXDText>
                          </TXDFlex>
                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="normal"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.AddDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="normal"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.RequestDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="normal"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.ApplicableDt
                              )}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="normal"
                            >
                              {formatDate(
                                transactionDetail?.TransactionData?.EftDt
                              )}
                            </TXDText>
                          </TXDFlex>

                          <TXDFlex variant="column" className="gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              TRANSACTION SOURCE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase whitespace-nowrap"
                              size="sm"
                              weight="bold"
                            >
                              TRANSACTION DESTINATION
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              TRANSACTION TYPE
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="bold"
                            >
                              ACTION
                            </TXDText>
                          </TXDFlex>

                          <TXDFlex variant="column" className=" gap-y-4">
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase whitespace-nowrap"
                              size="sm"
                              weight="normal"
                            >
                              {/* Payroll */}
                              {transactionDetail?.TransactionData?.SourceDesc}
                            </TXDText>

                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase  font-normal"
                              size="sm"
                              weight="normal"
                            >
                              <span className="border-b-2 border-green-500">
                                {
                                  transactionDetail?.TransactionData
                                    ?.DestinationDesc
                                }
                              </span>{" "}
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase whitespace-nowrap"
                              size="sm"
                              weight="normal"
                            >
                              {transactionDetail?.TransactionData?.Desc}
                            </TXDText>
                            <TXDText
                              as="span"
                              className="text-gray-500 uppercase"
                              size="sm"
                              weight="normal"
                            >
                              {" "}
                              {
                                transactionDetail?.TransactionData
                                  ?.DestinationType
                              }
                              {/* Payroll Deduction */}
                            </TXDText>
                            {transactionDetail?.TransactionData?.Action}
                          </TXDFlex>
                        </TXDBox>
                        {/* 3rd  */}

                        <TXDBox className=" ">
                          <TXDBox className=" bg-white  border-solid ">
                            <TXDBox className="">
                              <TXDBox className="participant-table min-w-full overflow-y-auto px-4  overflow-x-auto max-h-72 align-middle">
                                <table className="min-w-full trans-fund-table ">
                                  <thead className="bg-white">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap py-3.5 pl-4  text-left text-sm font-bold text-gray-900 sm:pl-4"
                                      >
                                        FUNDS
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap pr-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        SYMBOL
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        UNITS
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        PRICE
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        TRADE DATE
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                      >
                                        AMOUNT
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {transactionDetail?.Funds.map(
                                      (fund: any, index: number) => (
                                        <tr key={index}>
                                          <td className="whitespace-nowrap py-2  font-normal text-sm text-gray-500 sm:pl-4 ">
                                            {fund.mfldesc}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                            {fund.Symbol}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                            {fund.facunits}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                            {transactionDetail?.ACH?.length >
                                            0 ? (
                                              transactionDetail.ACH.map(
                                                ({ ach, index }: any) => (
                                                  <div key={index}>
                                                    {ach.amount || "N/A"}
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <div>N/A</div>
                                            )}
                                          </td>

                                          <td className="whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                            {transactionDetail
                                              ?.CheckDistribution?.length >
                                            0 ? (
                                              transactionDetail.CheckDistribution.map(
                                                ({ ach, index }: any) => (
                                                  <div key={index}>
                                                    {ach.amount || "N/A"}
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <div>N/A</div>
                                            )}
                                          </td>

                                          <td className=" whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                            {`$${fund.Amount.toLocaleString()}`}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </TXDBox>
                            </TXDBox>
                          </TXDBox>
                        </TXDBox>
                      </TXDFlex>
                    </TXDBox>
                    <TXDBox className="shadow-sm bg-white  mt-6  ">
                      <TXDBox className="  bg-[#FAFAFA]   ">
                        <TXDFlex
                          alignItems="center"
                          className=" flex  justify-between "
                        >
                          <TXDText
                            align="left"
                            as="h1"
                            className="uppercase pb-4 pt-4 pl-16    text-gray-500"
                            size="sm"
                            weight="bold"
                          >
                            MESSAGES FOR TRANSACTIONS (2)
                          </TXDText>
                        </TXDFlex>
                      </TXDBox>

                      <TXDFlex className="pb-4 justify-between px-4 sm:px-16 pt-6 border border-gray gap-6">
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase "
                          size="sm"
                          weight="bold"
                        >
                          <FontAwesomeIcon
                            icon={faCaretRight}
                            className="h-3 w-3  text-gray-500 uppercase"
                          />{" "}
                          Trade Action:Confirmed
                        </TXDText>
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase"
                          size="sm"
                          weight="normal"
                        >
                          ID{" "}
                          {
                            transactionDetail?.TransactionData
                              ?.TransactionPermId
                          }
                        </TXDText>
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase"
                          size="sm"
                          weight="normal"
                        >
                          September 2, 2017 10:44 PM
                        </TXDText>
                      </TXDFlex>

                      <TXDFlex className="px-4 sm:px-16 gap-6 pt-6 pb-10 justify-between ">
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase "
                          size="sm"
                          weight="bold"
                        >
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            className="h-3 w-3  text-gray-900 uppercase"
                          />{" "}
                          Trade Request Accepted
                        </TXDText>
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase"
                          size="sm"
                          weight="normal"
                        >
                          ID{" "}
                          {
                            transactionDetail?.TransactionData
                              ?.TransactionPermId
                          }
                        </TXDText>
                        <TXDText
                          as="span"
                          className="text-gray-500 uppercase"
                          size="sm"
                          weight="normal"
                        >
                          September 2, 2017 10:44 PM
                        </TXDText>
                      </TXDFlex>
                      <TXDFlex variant="column" className="pb-4 px-4 sm:px-16">
                        <TXDText
                          as="span"
                          className="text-gray-500 "
                          size="sm"
                          weight="normal"
                        >
                          Qualified recently received the following unsolicited
                          trade order posted to your investment
                        </TXDText>

                        <TXDBox className="grid gap-4 mt-6 grid-cols-[180px_auto] items-start  mb-1 ">
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500  inline-block w-[180px] "
                          >
                            Date Received:
                          </TXDText>
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500 "
                          >
                            Sep 1 2017 10:25 AM
                          </TXDText>
                        </TXDBox>

                        <TXDBox className="grid gap-4 grid-cols-[180px_auto] items-start mt-1 mb-1">
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500  inline-block w-[180px] "
                          >
                            Trade Action:
                          </TXDText>
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500"
                          >
                            Investment From Payroll Deduction to Traditional IRA
                            $
                          </TXDText>
                        </TXDBox>

                        <TXDBox className="grid gap-4 grid-cols-[180px_auto] items-start mt-1 mb-1">
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500  inline-block w-[180px] "
                          >
                            Total Dollar Amount:
                          </TXDText>
                          <TXDText
                            as="span"
                            size="sm"
                            weight="normal"
                            className="text-gray-500 "
                          >
                            $ 80
                          </TXDText>
                        </TXDBox>
                      </TXDFlex>

                      <TXDBox className=" p-4 sm:max-w-3xl max-w-3xl pl-4 sm:pl-8 ">
                        <TXDBox className=" bg-white border-b-2 border-solid border-[#f9f9f9]">
                          <TXDBox className="funds-table overflow-y-auto px-4 overflow-x-auto max-h-72 align-middle ">
                            <table className="min-w-full ">
                              <thead className="bg-white ">
                                <tr>
                                  <th
                                    scope="col"
                                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                  >
                                    FUNDS
                                  </th>
                                  <th
                                    scope="col"
                                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-bold text-gray-900"
                                  >
                                    SYMBOL
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionDetail?.Funds.map(
                                  (fund: any, index: number) => (
                                    <tr key={index}>
                                      <td className=" whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                        {fund.mfldesc}
                                      </td>
                                      <td className=" whitespace-nowrap px-2 py-2 text-sm font-normal text-gray-500">
                                        {fund.Symbol}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </TXDBox>
                        </TXDBox>
                      </TXDBox>

                      <TXDBox>
                        <TXDText
                          as="p"
                          className="sm:pl-16 pl-4 mb-8 mt-8 flex text-gray-500"
                          size="sm"
                          weight="normal"
                        >
                          <TXDBox className="flex-shrink-0">
                            <FontAwesomeIcon
                              className="text-gray-500 mt-1"
                              icon={faCircleExclamation}
                            />
                          </TXDBox>
                          <TXDBox className="pl-2 sm:pl-6 flex-1 leading-5">
                            Qualified will execute this trade request at the
                            next close of business after the markets have
                            determined the current market price for your <br />{" "}
                            investment fund(s). After our attempt to execute
                            this trade you will receive another email detailing
                            the resolution of this trade request.
                          </TXDBox>
                        </TXDText>

                        <TXDText
                          as="p"
                          className="sm:pl-16 pl-4 pb-12 text-gray-500 leading-5"
                          size="sm"
                          weight="normal"
                        >
                          If you have any questions regarding this message or
                          your investment account with Qualifed, please feel
                          free to call our client support <br />
                          department at 1-877-728-3359, weekdays between the
                          hours of 7:30am and 5:30pm PST, or email
                          support@savedaily.com.{" "}
                        </TXDText>
                      </TXDBox>

                      <TXDFlex
                        className=" gap-7 mr-8 mb-8"
                        justifyContent="end"
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          onClick={handleDownload}
                          className="h-3 w-3  text-gray-400 cursor-pointer"
                        />

                        <FontAwesomeIcon
                          icon={faPrint}
                          onClick={handlePrint}
                          className="h-3 w-3  text-gray-400 cursor-pointer"
                        />
                      </TXDFlex>
                    </TXDBox>
                  </TXDBox>
                </TXDFlex>
              )}
            </TXDBox>
          </Transition.Child>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShowTransacationModal;
