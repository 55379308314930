import React, { useState } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDInput,
  TXDText,
  TXDSelect,
  TXDImage,
  TXDRadio,
} from "@supporttxend/txd-components";
import curveSvg from "../../../assets/svg/curve.svg";
import toast from "react-hot-toast";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";
import { REGEX_CONSTANTS } from "@/constants/regexConstants";

const TPACreateAccount: React.FC = () => {
  const [showExtraText, setShowExtraText] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    input1: false,
    input2: false,
    input3: false,
    input4: false,
    input5: false,
    input6: false,
    input7: false,
    input8: false,
    input9: false,
    input10: false,
    input11: false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const hasError = Object.values(inputErrors).some((error) => error);
    if (hasError) {
      toast.error(ERROR_MESSAGES.ERROR_SUBMIT);
      return;
    }
    const adminstrativefirm = e.target[0].value;
    const taxid = e.target[1].value;
    const title = e.target[2].value;
    const bussinesType = e.target[3].value;
    const streetaddress = e.target[4].value;
    const streetaddress2 = e.target[5].value;
    const selectCity = e.target[6].value;
    const selectState = e.target[7].value;
    const zipCode = e.target[8].value;
    const cfirstname = e.target[9].value;
    const llastname = e.target[10].value;
    const cphonenumber = e.target[11].value;
    const email = e.target[12].value;

    const formData = {
      adminstrativefirm,
      taxid,
      title,
      bussinesType,
      streetaddress,
      streetaddress2,
      selectCity,
      selectState,
      zipCode,
      cfirstname,
      llastname,
      cphonenumber,
      email,
    };

    console.table(formData);
  };

  const updateErrorState = (inputName: string, error: boolean) => {
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [inputName]: error,
    }));
  };

  return (
    <TXDFlex
      justifyContent="center"
      alignItems="center"
      className="relative px-4"
      variant="column"
    >
      <TXDText
        as="h1"
        size="2xl"
        weight="medium"
        className="text-[#333333] mt-16 mb-8"
      >
        Set up your users on this account
      </TXDText>
      <form onSubmit={handleSubmit}>
        <TXDFlex className="flex-col w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-[428px] relative z-10 gap-2">
          <TXDBox>
            <TXDInput
              id="email"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Contact Email Address"}
              radius="md"
              sizing="xs"
              type="email"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.EMAIL_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input13", error ? true : false)
              }
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="fname"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="First Name"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.FIRST_NAME_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input1", error ? true : false)
              }
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="lname"
              label
              labelClass="font-bold cursor-pointer text-[#A2A2A2] text-xs uppercase"
              labelText="Last Name"
              radius="md"
              sizing="xs"
              type="text"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.LAST_NAME_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input2", error ? true : false)
              }
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="streetaddress"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Street Address"}
              radius="md"
              sizing="xs"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.STREET_ADDRESS_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input5", error ? true : false)
              }
            />
            <TXDText size="xs">(Cannot be a P.O. Box)</TXDText>
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="streetaddressTwo"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Street Address 2 (Optional)"}
              radius="md"
              sizing="xs"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.STREET_ADDRESS_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input6", error ? true : false)
              }
            />
          </TXDBox>
          <TXDFlex className="justify-between w-full" alignItems="center">
            <TXDBox className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
              <TXDSelect
                defaultValue=""
                id="selectCity"
                isObjectArray
                label
                labelClass="font-bold text-[#A2A2A2] text-xs"
                variant="regular"
                className="focus:ring-0 focus:ring-transparent uppercase border border-gray-200"
                labelText={"City"}
                optionsArray={[
                  { disabled: true, label: "Select City", value: "" },
                  { label: "Mexico City", value: "MXC" },
                  { label: "Toronto", value: "TOR" },
                  { label: "London", value: "LDN" },
                  { label: "Sydney", value: "SYD" },
                ]}
                sizing="xs"
              />
              <TXDSelect
                defaultValue=""
                id="selectState"
                isObjectArray
                label
                labelClass="font-bold text-[#A2A2A2] text-xs"
                variant="regular"
                className="focus:ring-0 focus:ring-transparent uppercase border border-gray-200 select-nonoverlap"
                labelText={"State or Province"}
                optionsArray={[
                  {
                    disabled: true,
                    label: "Select state or province",
                    value: "",
                  },
                  { label: "Mexico", value: "MX" },
                  { label: "Canada", value: "CA" },
                  { label: "United Kingdom", value: "UK" },
                  { label: "Australia", value: "AU" },
                ]}
                sizing="xs"
              />
            </TXDBox>
          </TXDFlex>
          <TXDBox>
            <TXDInput
              id="zipCode"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Zip Code"}
              radius="md"
              sizing="xs"
              required
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.ZIP_CODE_REGEX}
              errorExist={(error: any) =>
                updateErrorState("input6", error ? true : false)
              }
            />
          </TXDBox>
          <TXDBox>
            <TXDInput
              id="cphonenumber"
              label
              labelClass="font-bold text-[#A2A2A2] text-xs"
              labelText={"Contact Phone Number"}
              radius="md"
              sizing="xs"
              type="tel"
              variant="regular"
              className="focus:ring-0 focus:ring-transparent"
              regex={REGEX_CONSTANTS.PHONE_REGEX}
              required
              errorExist={(error: any) =>
                updateErrorState("input12", error ? true : false)
              }
            />
          </TXDBox>
          <TXDFlex variant={"column"} alignItems={"center"} className="mt-3">
            <TXDText
              as="label"
              className="leading-4 text-sm font-normal cursor-pointer"
            >
              Would you like to add an another user to this account?
            </TXDText>
            <TXDRadio
              inline
              isObjectArray
              labelClass=""
              name="languages"
              onChange={(e: any) => alert(e.target.value)}
              radioArray={[
                {
                  id: "yes",
                  label: "Yes",
                  value: "yes",
                },
                {
                  id: "no",
                  label: "No",
                  value: "no",
                },
              ]}
            />
          </TXDFlex>
          {showExtraText && (
            <>
              <TXDText
                weight="normal"
                className="leading-4 sm:text-[17px] text-[10px] mb-4 sm:bottom-36 w-auto absolute sm:left-[-220px] left-0 z-10 sm:whitespace-nowrap whitespace-pre-wrap hidden sm:block"
              >
                The US Portal Service suggests the address displayed below.
                Please select the best address to use.
              </TXDText>
              <TXDFlex alignItems="center" className="mt-14 ">
                <TXDText
                  className="text-red-500"
                  size="xs"
                  weight="medium"
                  style={{
                    width: "130px",
                    flexShrink: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  Entered Address:
                </TXDText>
                <TXDRadio
                  inline
                  isObjectArray
                  labelClass="font-medium text-xs text-[#000000]"
                  className="text-[#0075FF] ml-4"
                  name="languages"
                  radioArray={[
                    {
                      id: "yes",
                      isChecked: true,
                      label: "XYZ, Newyork, NY, 76904",
                      value: "yes",
                    },
                  ]}
                />
              </TXDFlex>
              <TXDFlex alignItems="center" className="">
                <TXDText
                  className="text-red-500"
                  size="xs"
                  weight="medium"
                  style={{
                    width: "130px",
                    flexShrink: 0,
                    whiteSpace: "nowrap",
                  }}
                >
                  Postal Recommendations:
                </TXDText>
                <TXDRadio
                  inline
                  isObjectArray
                  labelClass="font-medium text-xs text-[#000000]"
                  className="text-[#0075FF] ml-4"
                  name="languages"
                  radioArray={[
                    {
                      id: "yes",
                      isChecked: true,
                      label: "XYZ, Newyork, NY, 76904",
                      value: "yes",
                    },
                  ]}
                />
              </TXDFlex>
            </>
          )}
          <TXDFlex justifyContent="center" className="gap-[28px] mt-6">
            <TXDButton
              radius="md"
              size="sm"
              variant="primary"
              className="bg-[#757575] uppercase  py-2 text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 hover:bg-white hover:text-[#757575] hover:ring-secondary-400   w-[100px]"
            >
              CANCEL
            </TXDButton>
            <TXDButton
              onClick={() => setShowExtraText(true)}
              radius="md"
              size="sm"
              variant="primary"
              className="bg-[#52A546] py-2  text-white text-xs md:text-xs lg:text-sm shadow-sm ring-1 ring-secondary-400 
              hover:bg-white hover:text-[#52A546] hover:ring-[#52A546] hover:shadow-lg   w-[100px]"
            >
              NEXT
            </TXDButton>
          </TXDFlex>
        </TXDFlex>
      </form>

      <TXDImage
        src={curveSvg}
        className="absolute top-[410px] w-full h-[1024px] opacity-100 z-0"
      />
    </TXDFlex>
  );
};

export default TPACreateAccount;
