import React from "react";
import { TXDBox } from "@supporttxend/txd-components";

const TableSkeleton: React.FC = () => {
  const shimmerStyle = {
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s linear infinite",
  };

  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `;

  const rows = Array.from({ length: 5 }).map((_, rowIndex) => (
    <tr
      key={rowIndex}
      className="animate-pulse"
      data-testid={`Loading-${rowIndex}`}
    >
      {Array.from({ length: 7 }).map((_, cellIndex) => (
        <td key={cellIndex} className="px-6 py-4">
          <TXDBox className="h-4 bg-gray-200 rounded relative overflow-hidden z-10">
            <TXDBox className="h-10 w-full bg-gray-200 rounded-md overflow-hidden">
              <TXDBox
                className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 "
                style={shimmerStyle}
              ></TXDBox>
            </TXDBox>
            <TXDBox
              className="absolute inset-0 bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
        </td>
      ))}
    </tr>
  ));

  return (
    <tbody className="bg-white divide-y divide-gray-200 z-0">
      <style>{shimmerKeyframes}</style>
      {rows}
    </tbody>
  );
};

export default TableSkeleton;
