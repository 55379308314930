import React from "react";
import { TXDBox } from "@supporttxend/txd-components";

const TabsSkeleton: React.FC = () => {
  const shimmerStyle = {
    backgroundSize: "200% 100%",
    animation: "shimmer 1.5s linear infinite",
  };

  const shimmerKeyframes = `
    @keyframes shimmer {
      0% {
        background-position: -200% 0;
      }
      100% {
        background-position: 200% 0;
      }
    }
  `;

  const rows = Array.from({ length: 3 }).map((_, rowIndex) => (
    <TXDBox key={rowIndex} className="animate-pulse flex flex-row mb-4">
      <TXDBox className="w-1/2 px-2">
        <TXDBox className="h-4 bg-gray-200 rounded relative overflow-hidden z-10 mb-2">
          <TXDBox className="h-10 w-full bg-gray-200 rounded-md overflow-hidden">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 "
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
        </TXDBox>
      </TXDBox>
      <TXDBox className="w-1/2 px-2">
        <TXDBox className="h-4 bg-gray-200 rounded relative overflow-hidden z-10 mb-2">
          <TXDBox className="h-10 w-full bg-gray-200 rounded-md overflow-hidden">
            <TXDBox
              className="w-full h-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200 "
              style={shimmerStyle}
            ></TXDBox>
          </TXDBox>
        </TXDBox>
      </TXDBox>
    </TXDBox>
  ));

  return (
    <TXDBox className="bg-white divide-y divide-gray-200 z-0 p-4">
      <style>{shimmerKeyframes}</style>
      {rows}
    </TXDBox>
  );
};

export default TabsSkeleton;
