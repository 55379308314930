import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../redux/store";
import QuickAccessButton from "@/components/QuickAccessButton";

const ProtectedRoute: React.FC = () => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  return isAuthenticated ? (
    <>
      <QuickAccessButton />
      <Outlet />
    </>
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default ProtectedRoute;
