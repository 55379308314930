import React from "react";
import PageWrapper from "./PageWrapper";
import NavHeader from "./NavHeader";
import InfoTile from "@/components/InfoTile";
import CardSkeleton from "@/layouts/skeletons/CardSkeleton";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import Footer from "./Footer";
import ProfileSkeleton from "@/layouts/skeletons/ProfileSkeleton";
import { TXDFlex } from "@supporttxend/txd-components";

const PageShimmer = ({ data }: any) => {
  return (
    <PageWrapper>
      <NavHeader data={data} />
      <TXDFlex
        variant="column"
        className="shadow-lg mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8 bg-white"
      >
        <ProfileSkeleton />
      </TXDFlex>
      <InfoTile>
        <CardSkeleton />
        <CardSkeleton />
      </InfoTile>
      <TXDFlex
        variant="column"
        className="shadow-lg mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8 bg-white"
      >
        <table className="min-w-full divide-y divide-gray-300">
          <TableSkeleton />
        </table>
      </TXDFlex>

      <Footer />
    </PageWrapper>
  );
};

export default PageShimmer;
