import React from "react";
import rolloverLogo from "../assets/images/logo-rollover.png";
import {
  TXDBox,
  TXDImage,
  TXDFlex,
  TXDText,
} from "@supporttxend/txd-components";

const SignUpFooter: React.FC = () => {
  return (
    <footer className="py-10 mt-20 flex justify-center footer__img z-10 px-4">
      <TXDBox className="container">
        <TXDFlex variant="row" justifyContent="center" className="py-10">
          <TXDImage
            src={rolloverLogo}
            alt="Website Logo"
            className="sm:w-96 w-64"
          />
        </TXDFlex>
        <TXDFlex variant="row" className="justify-start sm:justify-center">
          <TXDFlex className="grid grid-cols-1 sm:grid-cols-2 sm:gap-8 md:grid-cols-4 md:gap-x-10 lg:grid-cols-4 lg:gap-x-24">
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText
                  as="span"
                  className="font-bold uppercase text-[#4B6795]"
                >
                  Home
                </TXDText>
              </TXDFlex>
              <TXDFlex className="py-2">
                <ul>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    About Us
                  </li>
                </ul>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText
                  as="span"
                  className="font-bold uppercase text-[#4B6795]"
                >
                  Getting STarted
                </TXDText>
              </TXDFlex>
              <TXDFlex className="py-2">
                <ul>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Professionals
                  </li>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Uncashed Checks
                  </li>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Individuals
                  </li>
                </ul>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText
                  as="span"
                  className="font-bold uppercase text-[#4B6795]"
                >
                  Resources
                </TXDText>
              </TXDFlex>
              <TXDFlex className="py-2">
                <ul>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    {"FAQ's"}
                  </li>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Documents
                  </li>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Contact US
                  </li>
                </ul>
              </TXDFlex>
            </TXDBox>
            <TXDBox className="flex flex-col">
              <TXDFlex className="py-2">
                <TXDText
                  as="span"
                  className="font-bold uppercase text-[#4B6795]"
                >
                  Legal
                </TXDText>
              </TXDFlex>
              <TXDFlex className="py-2">
                <ul>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Terms & Conditions
                  </li>
                  <li className="py-2 text-[#6E92B5] hover:text-white cursor-pointer">
                    Privacy Statement
                  </li>
                </ul>
              </TXDFlex>
            </TXDBox>
          </TXDFlex>
        </TXDFlex>
        <TXDBox className="py-4 my-4 footer__descp">
          <p>
            The investments you make through your Rolloversaver® account are not
            federally insured, are not guarantees or obligations of
            Rolloversaver®, UMB Bank and may involve risk, including possible
            loss of principal.
          </p>

          <p>
            self-directed investment platform offered by Rolloversaver®.
            Rolloversaver® is not affiliated with any advisor whose name appears
            on the Rolloversaver® website. The information contained in the
            InvestedHealth® website should not be considered to be either a
            recommendation by Rollloversaver® or a solicitation of any offer to
            purchase or sell any securities.
          </p>
          <p>
            Rolloversaver® is not a registered investment adviser and does not
            provide investment or financial advice. Please consult with your
            personal tax or financial advisor.
          </p>
        </TXDBox>
      </TXDBox>
    </footer>
  );
};

export default SignUpFooter;
