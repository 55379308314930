export const requestMethods = Object.freeze({
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
});

export const userPolicyId = Object.freeze({
  TPAPolicy: 162,
  PAPolicy: 160,
  PSPolicy: 161,
});

export const rolePageId = Object.freeze({
  TPADashboard: 1023,
  PADashboard: 1039,
  PPDashboard: 1040,
  PSDashboard: 1041,
});

export const userRoles = Object.freeze({
  TPA: "tpa",
  SPONOSR: "sponsor",
  ADMINISTRATOR: "administrator",
  PARTICIPANT: "participant",
});

export const API_BODY_CONSTANTS = Object.freeze({
  REFER_VALUE: "Generic",
  OPTIONS: 10,
  ADDRESS_TYPE_PERM_ID: 100,
  REGISTER_PORTFOLIO_STATUS: 119,
});

export const SiteId = "0080";
