import React from "react";
import PageWrapper from "@/components/PageWrapper";
import SignupNavbar from "@/components/SignupNavbar";
import SignUpFooter from "@/components/SignUpFooter";
import ResetPassword from "@/components/ResetPassword";

const ResetPasswordPage: React.FC = () => {
  return (
    <PageWrapper>
      <SignupNavbar />
      <ResetPassword />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default ResetPasswordPage;
