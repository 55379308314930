import React from "react";
import PageWrapper from "@/components/PageWrapper";
import LogIn from "@/components/LogIn";
import SignupNavbar from "@/components/SignupNavbar";
import SignUpFooter from "@/components/SignUpFooter";

const Login: React.FC = () => {
  return (
    <PageWrapper>
      <SignupNavbar />
      <LogIn />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default Login;
