import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { INTERNAL_PARTICIPANT_UPLOAD_RQ } from "./api/userService";
import { toast } from "react-hot-toast";
import { UploadParticipantBodySchema } from "@/constants/validations";
import {
  fileToBase64,
  processExcelFile,
  validateFileObjects,
} from "@/utils/helpers";
import {
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";

const useUploadParticipant = (file: File | null, closeModal: () => void) => {
  const [uploadParticipant, setUploadParticipant] = useState<
    (() => void) | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const portfolioPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const agentPermId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const accessCode = useSelector(
    (state: RootState) => state.auth.AccessCode as string
  );

  const uploadFile = async () => {
    if (file && portfolioPermId && agentPermId) {
      setLoading(true);
      try {
        const bufferData = await fileToBase64(file);
        const jsonFile = await processExcelFile(file);
        const apiBody = {
          FileName: file.name,
          FileData: bufferData,
          PortfolioPermId: Number(portfolioPermId),
          AgentPermId: Number(agentPermId),
        };
        const validateBody = UploadParticipantBodySchema.parse(apiBody);
        if (validateFileObjects(jsonFile)) {
          await INTERNAL_PARTICIPANT_UPLOAD_RQ(validateBody, accessCode);
          toast.success(SUCCESS_MESSAGES.FILE_UPLOADED);
        } else {
          throw new Error(ERROR_MESSAGES.INVALID_FILE_DATA);
        }

        // console.log(response.data);
        closeModal();
      } catch (err: any) {
        const errorMessage = err.response?.data?.error || err.message;
        setError(errorMessage);
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setUploadParticipant(() => uploadFile);
  }, [file, portfolioPermId, agentPermId]);
  return { uploadParticipant, loading, error };
};

export default useUploadParticipant;
