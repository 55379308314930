import { getRolloverBaseUrl } from "@/utils/apiUtils";
import axiosInstance from "./interceptors";
import { requestMethods } from "@/constants/constant";

const rolloverRequestWrapper = async (
  method: string,
  url: string,
  data?: any,
  contentType?: string
) => {
  const headers = {
    "Content-Type": contentType || "application/json",
  };

  try {
    const response = await axiosInstance({
      method,
      url: getRolloverBaseUrl(url),
      headers,
      data,
    });

    return response.data;
  } catch (error: any) {
    const Extracterror = error.response.data.ExceptionDetails;
    return `${Extracterror ? Extracterror : error.message}`;
  }
};
const blobRequestWrapper = async (
  method: string,
  url: string,
  data?: any,
  contentType?: string
) => {
  const headers = {
    "Content-Type": contentType || "application/json",
  };

  try {
    const response = await axiosInstance({
      method,
      url: getRolloverBaseUrl(url),
      headers,
      data,
      responseType: "blob",
    });

    return response.data;
  } catch (error: any) {
    const apiError =
      error?.response?.data?.message?.ExceptionDetails?.ErrorDescription;
    throw new Error(`${apiError ? apiError : error.message}`);
  }
};
export const RolloverPost = (url: string, data: any) =>
  rolloverRequestWrapper(requestMethods.POST, url, data);
export const downloadBlobPost = (url: string, data: any) =>
  blobRequestWrapper(requestMethods.POST, url, data);
