import { ListParticipantFileDataSchema } from "@/constants/validations";
import * as XLSX from "xlsx";

import { IFileHeaderMapper, ISheetData } from "@/interfaces";

export const FileFieldsHeader: IFileHeaderMapper = Object.freeze({
  __EMPTY: "SponsorId",
  __EMPTY_1: "FirstName",
  __EMPTY_2: "MiddleName",
  __EMPTY_3: "LastName",
  __EMPTY_4: "StreetAddress",
  __EMPTY_5: "City",
  __EMPTY_6: "State",
  __EMPTY_7: "Zip",
  __EMPTY_8: "DOB",
  __EMPTY_9: "SSN",
  __EMPTY_10: "X",
});

export const filterFileData = (data: any[]): any[] => {
  const cleanedData: any[] = [];

  for (let i = 6; i < data.length; i++) {
    const row = data[i];
    const cleanedRow: any = {};

    for (const key in row) {
      const header = Object.keys(FileFieldsHeader).find(
        (headerKey) => key === headerKey
      );
      if (header) {
        // console.log(header);
        const cleanedKey = FileFieldsHeader[header];
        if (cleanedKey) {
          cleanedRow[cleanedKey] = row[key];
        }
      }
    }

    if (Object.keys(cleanedRow).length > 0) {
      cleanedData.push(cleanedRow);
    }
  }

  return cleanedData;
};

export const processExcelFile = async (
  file: File
): Promise<{ [key: string]: any }[]> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error("No file provided."));
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      try {
        const data = e.target?.result;
        if (data) {
          const workbook = XLSX.read(data, { type: "array" });
          const sheetNames = workbook.SheetNames;
          const jsonData: ISheetData[] = [];

          sheetNames.forEach((sheetName: any) => {
            const worksheet = workbook.Sheets[sheetName];
            if (worksheet) {
              const sheetData = XLSX.utils.sheet_to_json(worksheet);
              const cleanedData = filterFileData(sheetData); // Implement your cleaning logic here
              jsonData.push({ sheetName, data: cleanedData });
            } else {
              console.error(
                `Sheet ${sheetName} is undefined or cannot be read.`
              );
            }
          });

          // Return the parsed and cleaned data from the first sheet
          resolve(jsonData[0]?.data || []);
        } else {
          reject(new Error("Failed to read file data."));
        }
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const fileToBase64 = async (file: File): Promise<string> => {
  try {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const buffer = new Uint8Array(arrayBuffer);
        const base64String = btoa(String.fromCharCode(...buffer));
        resolve(base64String);
      };

      reader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };

      reader.readAsArrayBuffer(file);
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validateFileObjects = (data: any[]): boolean => {
  try {
    ListParticipantFileDataSchema.parse(data);
    return true;
  } catch (error) {
    return false;
  }
};
