import React from "react";
import { useLocation } from "react-router-dom";
import PageWrapper from "@/components/PageWrapper";
import SignUpFooter from "@/components/SignUpFooter";
import SignupNavbar from "@/components/SignupNavbar";
import TPAStartRollover from "../createAccount/StartRollover";

const Step1: React.FC = () => {
  const location = useLocation();
  let ComponentToRender;

  switch (location.pathname) {
    case "/start-rollover":
      ComponentToRender = TPAStartRollover;
      break;
    default:
      ComponentToRender = TPAStartRollover;
      break;
  }

  return (
    <PageWrapper>
      <SignupNavbar />
      <ComponentToRender />
      <SignUpFooter />
    </PageWrapper>
  );
};

export default Step1;
