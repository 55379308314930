import React, { useEffect, useState } from "react";
import {
  TXDBox,
  TXDButton,
  TXDFlex,
  TXDTable,
} from "@supporttxend/txd-components";
import UploadFileModal from "../layouts/modals/UploadFileModal";
import TableSkeleton from "@/layouts/skeletons/TableSkeleton";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddPlanSponsorModal from "@/layouts/modals/AddPlanSponsorModal";
import EditSponserModal from "@/layouts/modals/EditSponserModal";
import ShowCompanyInfoModal from "@/layouts/modals/ShowCompanyInfoModal";
import { ISponsorTable } from "@/interfaces";
import {
  formatSocialSecurity,
  getHumanReadableStatus,
} from "@/utils/componentUtils";
import { RO_CONSTANTS } from "@/constants/RolloverConstants";
import useFetchUserList from "@/services/useFetchUserList";

const SponsorsTable: React.FC<ISponsorTable> = (props) => {
  const { permissionsList } = props.componentPermissions;
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [upload, setUpload] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null);
  const [currentPortfolioPermId, setCurrentPortfolioPermId] = useState<
    number | null
  >(null);
  const [showCompany, setShowCompany] = useState(false);

  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = id || defaultId;
  const { users, loading, refetch } = useFetchUserList(
    RO_CONSTANTS.PORTFOLIO_TYPE_ID.PLAN_SPONSOR,
    effectiveId
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localSponsors, setLocalSponsors] = useState<ISponsorTable[]>(users);

  const handleEditClick = (portfolioPermId: number) => {
    const selectedSponsor = users.find(
      (user) => user.PortfolioPermId === portfolioPermId
    );
    setCurrentPortfolioPermId(portfolioPermId);
    setEditModal(true);
    setSelectedAdminId(selectedSponsor);
  };

  const underlineFirstFourLetters = (name: string) => {
    if (!name) return "Unknown";
    const firstFour = name.slice(0, 4);
    const rest = name.slice(4);
    return (
      <span>
        <span className="border-b-2 border-[#4EC6E5]">{firstFour}</span>
        {rest}
      </span>
    );
  };

  const formatSponsorsData = (data: any[]) => {
    if (!Array.isArray(data)) return [];
    return data.map((sponsor, index) => ({
      number: (index + 1).toString(),
      id: sponsor.PortfolioPermId || "N/A",
      sponsor: (
        <NavLink
          to={`/sponsor/${sponsor.PortfolioPermId}`}
          className="font-bold"
        >
          {underlineFirstFourLetters(sponsor.PortfolioFirstName)}{" "}
          {sponsor.PortfolioLastName || "N/A"}
        </NavLink>
      ),

      socialSecurity:
        formatSocialSecurity(sponsor.PortfolioTaxIdentifier) || "N/A",

      assets: sponsor.PortfolioAssetsLineage
        ? `$ ${sponsor.PortfolioAssetsLineage.toLocaleString()}`
        : "$ 0",
      status: getHumanReadableStatus(sponsor.PortfolioStatus),
      actions: (
        <TXDFlex className="gap-2" alignItems="center">
          {permissionsList.read ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="View">
              <FontAwesomeIcon
                icon={faEye}
                className="cursor-pointer text-[#00AFD8]"
                onClick={() => {
                  setSelectedAdminId(sponsor.PortfolioPermId);
                  setShowCompany(true);
                }}
              />
            </TXDBox>
          ) : (
            <></>
          )}

          {permissionsList.update ? (
            <TXDBox className="tooltip-wrapper" data-tooltip="Modify">
              <FontAwesomeIcon
                icon={faEdit}
                className="cursor-pointer text-[#38D6B5]"
                onClick={() => handleEditClick(sponsor.PortfolioPermId)}
              />
            </TXDBox>
          ) : (
            <></>
          )}
        </TXDFlex>
      ),
    }));
  };

  useEffect(() => {
    // console.log(users);
    setLocalSponsors(users);
  }, [users]);
  // useEffect(() => {
  //   console.log("localSponsors has been updated");
  // }, [localSponsors]);
  const handleAddSponsor = (newSponsor: ISponsorTable) => {
    setLocalSponsors((prevSponsors) => [...prevSponsors, newSponsor]);
  };

  const tableHead = [
    "Sr. #",
    "id",
    "Sponsors",
    "Social Security",
    "Assets",
    "Status",
    "Actions",
  ];
  const tableData = formatSponsorsData(users);

  const onUpdate = () => {
    refetch().then(() => {
      // console.log("Data refetched after update");
      setEditModal(false);
    });
  };

  return (
    <>
      {permissionsList.read ? (
        <TXDBox className="shadow-lg bg-[#ffffff]  mx-4 mt-10 sm:mx-4 sm:mt-5 md:mx-4 md:mt-5 lg:mx-7 lg:mt-7 xl:mx-32 xl:mt-8">
          <TXDBox className="overflow-x-auto">
            {loading ? (
              <table className="min-w-full divide-y divide-gray-300">
                <TableSkeleton />
              </table>
            ) : (
              <TXDTable
                filteration={true}
                pagination={true}
                entries={true}
                tHead={tableHead}
                tRow={tableData}
                title="Plan Sponsors"
              />
            )}
            {permissionsList.write ? (
              <TXDFlex
                className="w-full py-4  px-4 gap-4 bg-white"
                justifyContent="end"
              >
                {/* <TXDBox className="tooltip-wrapper" data-tooltip="Add"> */}
                <TXDButton
                  className="bg-white !text-[#4EC6E5] uppercase py-2 text-xs md:text-xs lg:text-sm shadow-sm  hover:bg-[#4EC6E5] hover:!text-white hover:ring-[#4EC6E5] text-medium font-semibold"
                  radius="none"
                  variant="primary"
                  onClick={() => setOpen(true)}
                >
                  <FontAwesomeIcon className="text-xs" icon={faPlus} /> Add
                  Sponsors
                </TXDButton>
                {/* </TXDBox> */}
              </TXDFlex>
            ) : (
              <></>
            )}

            {open && (
              <AddPlanSponsorModal
                modalOpen={open}
                closeModal={() => setOpen(false)}
                onAddSponsor={handleAddSponsor}
              />
            )}
            {editModal && currentPortfolioPermId !== null && (
              <EditSponserModal
                modalOpen={editModal}
                closeModal={() => {
                  setEditModal(false);
                }}
                onAddSponsor={handleAddSponsor}
                userProfile={selectedAdminId}
                onUpdate={onUpdate}
                portfolioPermId={currentPortfolioPermId}
              />
            )}

            {upload && (
              <UploadFileModal
                modalOpen={upload}
                closeModal={() => setUpload(false)}
              />
            )}
            {showCompany && selectedAdminId !== null && (
              <ShowCompanyInfoModal
                modalOpen={showCompany}
                closeModal={() => setShowCompany(false)}
                identifier={selectedAdminId}
              />
            )}
          </TXDBox>
        </TXDBox>
      ) : (
        <></>
      )}
    </>
  );
};

export default SponsorsTable;
