import React from "react";
import NavHeader from "@/components/NavHeader";
import PageWrapper from "@/components/PageWrapper";
import Footer from "@/components/Footer";
import AdministratorTableList from "@/components/AdministratorTable";
import CompanyTile from "@/components/CompanyTile";
import SponsorsTable from "@/components/SponsorsTable";
import ParticipantsTable from "@/components/ParticipantsTable";
import { findComponent, findPermissionList } from "@/utils/pageUtils";
import PageShimmer from "@/components/PageShimmer";
import { IThirdPartyAdministratorDashboard } from "@/interfaces";
import getPagePermissionList from "@/services/getPagePermission";
import { rolePageId, SiteId } from "@/constants/constant";
import InfoTile from "@/components/InfoTile";
import ReminderCard from "@/components/ReminderCard";
import { TXDBox } from "@supporttxend/txd-components";

const ThirdPartyAdministratorDashboard: React.FC<
  IThirdPartyAdministratorDashboard
> = ({ data }) => {
  const pageData = getPagePermissionList(SiteId, rolePageId.TPADashboard);

  if (!pageData || pageData.SectionList.length === 0 || !data.userProfile) {
    return <PageShimmer data={data} />;
  }

  const renderComponent = (
    componentName: any,
    Component: any,
    additionalProps = {}
  ) => {
    if (findComponent(componentName, pageData)) {
      return (
        <Component
          componentPermissions={findPermissionList(componentName, pageData)}
          data={data}
          {...additionalProps}
        />
      );
    }
    return null;
  };

  return (
    <PageWrapper>
      <NavHeader data={data} />
      {renderComponent("TPA DETAIL", CompanyTile)}
      <InfoTile>
        {renderComponent("PLAN ADMINS LIST", AdministratorTableList)}
        {renderComponent("ACTION REQUIRED", ReminderCard)}
      </InfoTile>
      <TXDBox className="pb-44">
        {renderComponent("SPONSORS LIST", SponsorsTable)}
      </TXDBox>
      {renderComponent("PARTICIPANT LIST", ParticipantsTable)}
      <Footer />
    </PageWrapper>
  );
};

export default ThirdPartyAdministratorDashboard;
