import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faUsers,
  faThLarge,
  faHandshake,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import { toast } from "react-hot-toast";

import profileImage from "../assets/images/profile.png";
import {
  TXDFlex,
  TXDImage,
  TXDBox,
  TXDText,
} from "@supporttxend/txd-components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/redux/store";
import { logout } from "@/redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { ISidebarProps } from "@/interfaces";
import { userPolicyId } from "@/constants/constant";
import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  SUCCESS_MESSAGES,
} from "@/layouts/skeletons/toastUtils";

const Sidebar: React.FC<ISidebarProps> = ({ sidebarActive, data }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { userProfile } = data;
  const userPolicy = useSelector((state: RootState) => state.auth.policy);

  const getSidebarSectionList = (policyId: any) => {
    switch (policyId) {
      case userPolicyId.TPAPolicy:
        return [
          { navLabels: "Dashboard", to: "/", icon: faThLarge },
          { navLabels: "Plan Advisors", to: "/administrators", icon: faUsers },
          { navLabels: "Plan Sponsors", to: "/sponsors", icon: faHandshake },
          {
            navLabels: "Plan Participants",
            to: "/participants",
            icon: faUsers,
          },
          { navLabels: "Reminders", to: "/reminders", icon: faCalendar },
        ];
      case userPolicyId.PAPolicy:
        return [
          { navLabels: "Dashboard", to: "/", icon: faThLarge },
          { navLabels: "Plan Sponsors", to: "/sponsors", icon: faHandshake },
          {
            navLabels: "Plan Participants",
            to: "/participants",
            icon: faUsers,
          },
          { navLabels: "Reminders", to: "/reminders", icon: faCalendar },
        ];
      case userPolicyId.PSPolicy:
        return [
          { navLabels: "Dashboard", to: "/", icon: faThLarge },
          {
            navLabels: "Plan Participants",
            to: "/participants",
            icon: faUsers,
          },
        ];
      default:
        return [{ navLabels: "Dashboard", to: "/", icon: faThLarge }];
    }
  };

  const navigation = getSidebarSectionList(userPolicy?.PolicyId);

  const firstName =
    userProfile === null
      ? ""
      : userProfile?.InternalPortfolio?.PortfolioFirstName || "";
  const lastName =
    userProfile === null
      ? ""
      : userProfile?.InternalPortfolio?.PortfolioLastName || "";
  const legalName =
    userProfile === null ? "" : userProfile?.Portfolio?.LegalName || "";
  const displayName =
    firstName || lastName ? `${firstName} ${lastName}`.trim() : legalName;

  const navigate = useNavigate();

  // useEffect(() => {}, [policy]);
  const handleLogout = () => {
    toast
      .promise(
        new Promise((resolve, reject) => {
          try {
            dispatch(logout());
            resolve(SUCCESS_MESSAGES.LOG_OUT_SUCC);
          } catch (error) {
            reject(ERROR_MESSAGES.LOG_OUT_FAILED);
          }
        }),
        {
          loading: LOADING_MESSAGES.LOGOUT_LOADING,
          success: <b>{SUCCESS_MESSAGES.LOG_OUT}</b>,
          error: <b>{ERROR_MESSAGES.LOG_OUT_FAILED}</b>,
        }
      )
      .then(() => {
        navigate("/sign-in");
      })
      .catch((error: any) => {
        toast.error(ERROR_MESSAGES.LOG_OUT_FAILED || error?.message);
      });
  };

  return (
    <TXDBox
      className={`bg-[#0F3445] transition-transform duration-300 ease-in-out max-w-[18rem] w-full text-white h-screen fixed ${
        sidebarActive ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <TXDBox className="grid grid-cols-12 bg-[#00283A] divide-y-0">
        <TXDFlex
          justifyContent="center"
          className="col-span-1 py-4 sm:py-5 md:py-6"
        ></TXDFlex>
        <TXDFlex className="col-span-9 p-[1.68rem] md:p-[1.45rem] lg:p-[1.70rem] justify-center gap-[6px]">
          <TXDBox className="xl:ml-[30px]">
            <TXDImage
              display="inline-block"
              radius="full"
              size="xs"
              src={profileImage}
              variant="cover"
              className="h-7 w-7"
            />
          </TXDBox>
          <TXDFlex alignItems="center">
            <TXDText
              as="span"
              className="text-gray-500 whitespace-nowrap  text-[14px]"
              weight="normal"
            >
              {displayName}
            </TXDText>
          </TXDFlex>
        </TXDFlex>
        {/* <TXDFlex
          justifyContent="center"
          alignItems="center"
          className="col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2 py-4 sm:py-5 md:py-6"
        ></TXDFlex> */}
      </TXDBox>
      <TXDBox>
        <ul className="flex flex-col gap-y-2 mt-2 p-2">
          {navigation.map((page, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={page.to}
                  className={`group flex items-center justify-start gap-x-2 rounded-md p-3 px-4 text-sm leading-6 font-semibold text-gray-400
                  transition-colors duration-300 ease-in-out
                  ${
                    location.pathname === page.to
                      ? "bg-[#00283A] text-white"
                      : "bg-transparent"
                  }
                  hover:bg-[#00283A] hover:text-white w-full`}
                >
                  <FontAwesomeIcon
                    icon={page.icon}
                    className="h-4 w-4 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
                    aria-hidden="true"
                  />
                  <TXDText
                    size="base"
                    weight="thin"
                    className="ml-2 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
                  >
                    {page.navLabels}
                  </TXDText>
                </NavLink>
              </li>
            );
          })}
        </ul>
        <ul className="fixed w-full bottom-0 my-4 p-2">
          <li>
            <TXDText
              as="span"
              className="whitespace-nowrap bg-[#00283A] cursor-pointer hover:text-white group flex items-center justify-start gap-x-2 rounded-md p-3 px-4 text-sm leading-6 font-semibold text-gray-400 transition-colors duration-300 ease-in-out"
              size="lg"
              weight="normal"
              onClick={handleLogout}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className=""
                aria-hidden="true"
              />{" "}
              Logout
            </TXDText>
          </li>
        </ul>
      </TXDBox>
    </TXDBox>
  );
};

export default Sidebar;
