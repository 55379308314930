import React from "react";
import { TXDBox } from "@supporttxend/txd-components";
const DownloadSpinner = () => {
  return (
    <TXDBox className="px-4 pr-3">
      <TXDBox className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#00AFD8]"></TXDBox>
    </TXDBox>
  );
};

export default DownloadSpinner;
