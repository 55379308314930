import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { userPolicyId } from "@/constants/constant";
import useFetchProfile from "@/services/userProfile";
import ThirdPartyAdministratorDashboard from "./ThirdPartyAdministratorDashboard";
import PlanAdministratorDashboard from "./PlanAdministratorDashboard";
import PlanSponsorDashboard from "./PlanSponsorDashboard";

const Dashboard: React.FC = () => {
  const userPolicy = useSelector((state: RootState) => state.auth.policy);

  const data = useFetchProfile();

  const getDashboardComponent = (policyId: any) => {
    switch (policyId) {
      case userPolicyId.TPAPolicy:
        return <ThirdPartyAdministratorDashboard data={data} />;
      case userPolicyId.PAPolicy:
        return <PlanAdministratorDashboard data={data} />;
      case userPolicyId.PSPolicy:
        return <PlanSponsorDashboard data={data} />;
      default:
        return <></>;
    }
  };

  return <>{getDashboardComponent(userPolicy?.PolicyId)}</>;
};

export default Dashboard;
