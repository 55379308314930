import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../redux/store";
import {
  INTERNAL_PORTFOLIO_INQ,
  PORTFOLIO_INQ,
} from "@/services/api/userService";
import { ERROR_MESSAGES } from "@/layouts/skeletons/toastUtils";

const useFetchProfile = (effectId?: any) => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const AccessCode = useSelector((state: RootState) => state.auth.AccessCode);

  const defaultId = useSelector(
    (state: RootState) => state.auth.PortfolioPermId
  );
  const effectiveId = effectId ? effectId : defaultId;

  const fetchUserProfile = async () => {
    try {
      const InternalPortfolio = await INTERNAL_PORTFOLIO_INQ(
        Number(effectiveId),
        AccessCode
      );
      const Portfolio = await PORTFOLIO_INQ(Number(effectiveId), AccessCode);

      if (InternalPortfolio && Portfolio) {
        const data = {
          InternalPortfolio:
            InternalPortfolio.InternalPortfolioInqRs.PortfolioData,
          Portfolio: Portfolio.PortfolioInqRs.PortfolioData,
        };
        // console.log(data);
        setLoading(true);
        setUserProfile(data);
      } else {
        throw new Error(ERROR_MESSAGES.FETCH_PROFILE_FAILED);
      }

      // localStorage.setItem("userProfile", JSON.stringify(data));
      setLoading(false);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return { userProfile, loading, error, refetch: fetchUserProfile };
};

export default useFetchProfile;
